import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import useAuth from "../States/Auth/AuthHooks";
import "./LoginLayout.css";

const LoginLayout = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (auth.user) {
      if (auth.user?.inOnboarding) navigate("/GetStarted");
      else navigate("/Home");
    }
  }, [auth]);
  return (
    <div className={"RegistrationBackground"}>
      <div className="registrationCenter">
        <Outlet />
        <div className="registrationTextVid">
          <div>
            <h1 className="registrationTitle">
              Honeycomb helps your customers find food they want
            </h1>
          </div>
          <div className="registrationVideo">
            <iframe
              allow="autoplay; fullscreen; picture-in-picture; camera; microphone; display-capture"
              allowFullScreen=""
              allowtransparency="true"
              referrerPolicy="no-referrer-when-downgrade"
              className="registrationVideo"
              frameBorder="0"
              height="100%"
              width="100%"
              scrolling="no"
              src="https://play.vidyard.com/KrNzSEtAZGqmEv7ceoYY6o?disable_popouts=1&amp;disable_analytics=0&amp;preload=auto&amp;disable_larger_player=false&amp;controller=hubs&amp;action=show&amp;type=inline&amp;v=4.3.6"
              title="Client Testimonials"
              style={{
                backgroundColor: "transparent",
                width: "100%",
                height: "100%",
              }}
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginLayout;
