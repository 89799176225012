import React from "react";
import { useNavigate } from "react-router-dom";
import DefaultButton from "../Components/DefaultButton";
import "./Registration.css";

const LoginOverview = () => {
  let navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/Demo");
  };

  const handleNavigationToLogin = () => {
    navigate("/Login");
  };

  const handleNavigationToSignup = () => {
    navigate("/Signup");
  };

  return (
    <div className="gettingIn">
      <div className="wrapper">
        <img
          className="logo"
          src="https://honeycomb.ai/images/Logo-Color.svg"
          alt="Logo of Honeycomb"
        />
        <p className="text">Restaurant Admin Portal</p>
        <DefaultButton
          onClick={() => handleNavigationToLogin()}
          text={"Log In"}
          classNameProp={"cyan"}
        />
        <p className="text">or,</p>
        <DefaultButton
          onClick={() => handleNavigationToSignup()}
          text={"Sign Up"}
          classNameProp={"cyan"}
        />
      </div>
      <div className="wrapper">
        <p className="text">Need Help?</p>
        <DefaultButton
          onClick={() => handleNavigation()}
          text={"Book A Demo"}
          classNameProp={"cyan"}
        />
      </div>
    </div>
  );
};

export default LoginOverview;
