export const FIVE_MINUTES_IN_SECS = 5 * 60;

export const MENU_TYPES = {
  ITEM: "item",
  SECTION: "section",
  MENU: "menu",
};

export const deepClone = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};
