import { Routes, Route, Outlet, Navigate, useLocation } from "react-router-dom";
import Demo from "./Pages/Demo";
import ForgotPassword from "./Pages/ForgotPassword";
import LoginLayout from "./Layouts/LoginLayout";
import Login from "./Pages/Login";
import Signup from "./Pages/Signup";
import AuthRoute from "./Routes/AuthRoute";
import LocationsLayout from "./Layouts/LocationsLayout";
import GetStarted from "./Pages/GetStarted";
import RestaurantDetailsNavBar from "./Components/Navbars/RestaurantDetailsNavBar";
import ScrollToTop from "./Util/ScrollToTop";
import NoMatchFound from "./Pages/NoMatchFound";
import LocationInfo from "./Pages/LocationInfo";
import LocationPolicy from "./Pages/LocationPolicy";
import LocationContact from "./Pages/LocationContact";
import HomeLayout from "./Layouts/HomeLayout";
import Dashboard from "./Pages/Dashboard";
import LocationMenu from "./Pages/LocationMenu";
import PostRestaurant from "./Pages/PostRestaurant";
import RestaurantLocations from "./Pages/RestaurantLocations";
import RestaurantMenu from "./Pages/RestaurantMenu";
import RestaurantOverview from "./Pages/RestaurantOverview";
import BasicLocationInfo from "./Pages/BasicLocationInfo";
import LoginOverview from "./Pages/LoginOverview";
import LocationsSubBar from "./Components/Navbars/LocationsSubBar";
import Account from "./Pages/Account";
import AddItemPage from "./Pages/AddItemPage";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./App.css";
import SlugPage from "./Pages/SlugPage";

function App() {
  const location = useLocation();
  return (
    <ScrollToTop>
      <TransitionGroup component={null}>
        <CSSTransition key={location.key} classNames="fade" timeout={300}>
          <Routes location={location}>
            <Route element={<LoginLayout />}>
              <Route index path="/" element={<LoginOverview />} />
              <Route path="/Login" element={<Login />} />
              <Route path="/Signup" element={<Signup />} />
              <Route path="/ForgotPassword" element={<ForgotPassword />} />
              <Route path="/Demo" element={<Demo />} />
              <Route path="*" element={<LoginOverview />} />
            </Route>
            <Route
              path="/GetStarted"
              element={
                <AuthRoute>
                  <GetStarted />
                </AuthRoute>
              }
            />
            <Route
              element={
                <AuthRoute>
                  <HomeLayout />
                </AuthRoute>
              }
            >
              <Route path="/Home" element={<Outlet />}>
                <Route index element={<Dashboard />} />
                <Route path="Locations" element={<RestaurantLocations />} />
                <Route path="Account" element={<Account />} />
                <Route path=":index" element={<RestaurantOverview />} />
                <Route
                  path=":index/Post"
                  element={
                    <>
                      <LocationsSubBar />
                      <PostRestaurant />
                    </>
                  }
                />
                <Route path=":index/Menu" element={<Outlet />}>
                  <Route index element={<RestaurantMenu />} />
                  <Route path="AddItem" element={<AddItemPage />} />
                </Route>
                <Route
                  path=":index/Edit"
                  element={
                    <>
                      <LocationsSubBar editFlow={true} />
                      <Outlet />
                    </>
                  }
                >
                  <Route path="Details" element={<RestaurantDetailsNavBar />}>
                    <Route path="Basic" element={<BasicLocationInfo />} />
                    <Route path="Info" element={<LocationInfo />} />
                    <Route path="Policy" element={<LocationPolicy />} />
                    <Route path="Contact" element={<LocationContact />} />
                    <Route path="*" element={<Navigate to="Basic" />} />
                    <Route index element={<Navigate to="Basic" />} />
                  </Route>
                  <Route index element={<Navigate to="Details" />} />
                </Route>
                <Route index element={<Navigate to="Details" />} />
                <Route path="*" element={<Dashboard />} />
              </Route>
            </Route>
            <Route
              element={
                <AuthRoute>
                  <LocationsLayout />
                </AuthRoute>
              }
            >
              <Route path="/AddLocation" element={<Outlet />}>
                <Route path="Details" element={<RestaurantDetailsNavBar />}>
                  <Route path="Basic" element={<BasicLocationInfo />} />
                  <Route path="Info" element={<LocationInfo />} />
                  <Route path="Policy" element={<LocationPolicy />} />
                  <Route path="Contact" element={<LocationContact />} />
                  <Route path="*" element={<Navigate to="Basic" />} />
                  <Route index element={<Navigate to="Basic" />} />
                </Route>
                <Route path="Menu" element={<LocationMenu />} />
                <Route path="Slug" element={<SlugPage />} />
                <Route path="Post" element={<PostRestaurant />} />
                <Route index element={<Navigate to="Details" />} />
                <Route path="*" element={<Navigate to="Details" />} />
              </Route>
              <Route path="*" element={<NoMatchFound />} />
            </Route>
          </Routes>
        </CSSTransition>
      </TransitionGroup>
    </ScrollToTop>
  );
}
export default App;
