import React from "react";
import PropTypes from "prop-types";
import "./AllergenToken.css";
import DefaultText from "./DefaultText";

const AllergenToken = ({
  allergen,
  handleClick,
  classNameProp,
  contains = false,
  modifiable = false,
}) => {
  return (
    <label
      className={`allergenDiv ${contains ? "containsAllergenToken" : ""} ${
        modifiable ? "modifiableAllergenToken" : ""
      } ${classNameProp}`}
      onClick={handleClick}
    >
      <DefaultText
        classNameProp={`s12 ${
          contains || modifiable ? "whiteText" : "grayText"
        }`}
      >
        {allergen}
      </DefaultText>
    </label>
  );
};

AllergenToken.propTypes = {
  allergen: PropTypes.string,
  handleClick: PropTypes.func,
};

export default AllergenToken;
