import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { establishmentPracticesData } from "../Assets/StaticData";
import DefaultText from "../Components/DefaultText";
import DietaryInfoCard from "../Components/DietaryInfoCard";
import DietaryInfoContentBox from "../Components/DietaryInfoContentBox";
import { deepClone } from "../config/constants";
import { locationRouteState } from "../States/Atoms";
import "./LocationInfo.css";

const LocationInfo = () => {
  const [allergiesInfo, setAllergiesInfo] = useState({});
  const [locationRoute, setLocationRoute] = useRecoilState(locationRouteState);

  const handleClick = (id, bool) => {
    allergiesInfo[id].checked = bool;
    if (bool) {
      setLocationRoute({
        ...locationRoute,
        establishmentPractices: [...locationRoute.establishmentPractices, id],
      });
    } else {
      setLocationRoute({
        ...locationRoute,
        establishmentPractices: locationRoute.establishmentPractices.filter(
          (item) => item !== id
        ),
      });
    }
    setAllergiesInfo((prevState) => {
      return { ...prevState, ...allergiesInfo[id] };
    });
  };

  const MapAllergiesInfo = () => {
    return Object.keys(allergiesInfo)
      .slice(0, 8)
      .map((value, index) => {
        const { id, name, image, checked } = allergiesInfo[value];
        return (
          <DietaryInfoCard
            key={"diet" + id + index}
            id={id}
            src={image}
            title={name}
            checked={checked}
            handleClick={handleClick}
          />
        );
      });
  };

  const MapGlutenOptions = () => {
    return Object.keys(allergiesInfo)
      .slice(8, 11)
      .map((value, index) => {
        const { id, name, image, checked } = allergiesInfo[value];
        return (
          <DietaryInfoCard
            key={"diet" + id + index}
            id={id}
            src={image}
            title={name}
            checked={checked}
            handleClick={handleClick}
          />
        );
      });
  };

  const MapOtherDietOptions = () => {
    return Object.keys(allergiesInfo)
      .slice(11, 17)
      .map((value, index) => {
        const { id, name, image, checked } = allergiesInfo[value];
        return (
          <DietaryInfoCard
            key={"diet" + id + index}
            id={id}
            src={image}
            title={name}
            checked={checked}
            handleClick={handleClick}
          />
        );
      });
  };

  useEffect(() => {
    const newState = deepClone(establishmentPracticesData);
    for (let i = 0; i < locationRoute.establishmentPractices.length; i++) {
      const element = locationRoute.establishmentPractices[i];
      newState[element].checked = true;
    }
    setAllergiesInfo(newState);
  }, []);

  return (
    <div className="locationInfo">
      <h2 className="locationInfoTitle">Restaurant Dietary Info</h2>
      <DefaultText classNameProp={"s14"}>
        Click and select which of the following applies to your restaurant.
      </DefaultText>
      <div className="dietOptions">
        <DietaryInfoContentBox title="Allergies">
          <MapAllergiesInfo />
        </DietaryInfoContentBox>

        <DietaryInfoContentBox title="Gluten-Free Options">
          <MapGlutenOptions />
        </DietaryInfoContentBox>
        <DietaryInfoContentBox title="Other Diets">
          <MapOtherDietOptions />
        </DietaryInfoContentBox>
      </div>
    </div>
  );
};

export default LocationInfo;
