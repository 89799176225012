import axios from "axios";

const SERVER_URL = "https://sea-lion-app-2p5vm.ondigitalocean.app";

const SUCCESS = 200;

//signup function using axios
export const signup = async (email, password) => {
  try {
    const response = await axios.post(`${SERVER_URL}/auth/client/signup`, {
      email,
      password,
    });
    if (response.status === SUCCESS) {
      return response.data;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
};

//login function using axios
export const login = async (email, password) => {
  try {
    const response = await axios.post(`${SERVER_URL}/auth/client/login`, {
      email,
      password,
    });
    if (response.status === SUCCESS) return response.data;
    else return null;
  } catch (error) {
    return null;
  }
};

//get client info function using axios
export const getUserInfo = async (token) => {
  try {
    const response = await axios.get(`${SERVER_URL}/client/`, {
      headers: {
        Authorization: token,
      },
    });
    if (response.status === SUCCESS) return response.data;
    else return null;
  } catch (error) {
    return null;
  }
};

export const updateLocation = async (token, location) => {
  try {
    const response = await axios.post(`${SERVER_URL}/clients/`, location, {
      headers: {
        Authorization: token,
      },
    });
    if (response.status === SUCCESS) return response.data;
    else return null;
  } catch (error) {
    return null;
  }
};

export const getMenuItems = async (token, id) => {
  try {
    const response = await axios.get(`${SERVER_URL}/restaurants/${id}`, {
      headers: {
        Authorization: token,
      },
    });
    if (response.status === SUCCESS) return response.data;
    else return null;
  } catch (error) {
    return null;
  }
};

export const postRestaurant = async (token, restaurant) => {
  try {
    const response = await fetch(`${SERVER_URL}/restaurants/`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(restaurant),
    });

    if (response.status === SUCCESS) return await response.json();
    else return null;
  } catch (error) {
    return null;
  }
};

export const checkIsUniqueSlug = async (token, slug) => {
  try {
    const response = await axios.get(`${SERVER_URL}/slug/${slug}`, {
      headers: {
        Authorization: token,
      },
    });
    if (response.status === SUCCESS) return true;
    else return null;
  } catch (error) {
    return null;
  }
};

export const postSlug = async (token, id, slug) => {
  try {
    const response = await fetch(`${SERVER_URL}/slug/`, {
      method: "POST",
      headers: {
        Authorization: token,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        restaurantId: id,
        slugName: slug,
      }),
    });

    if (response.status === SUCCESS) return true;
    else return null;
  } catch (error) {
    return null;
  }
};
