import { atom } from "recoil";

export const userState = atom({
  key: "user",
  default: JSON.parse(localStorage.getItem("user")),
});

export const loadedState = atom({
  key: "loadedState",
  default: false,
});
