import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import AlertComponent from "../Components/AlertComponent";
import DefaultButton from "../Components/DefaultButton";
import DefaultInput from "../Components/DefaultInput";
import useAuth from "../States/Auth/AuthHooks";
import "./Registration.css";

const Signup = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [message, setMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleSignUp = async () => {
    setLoading(true);
    if (email.length < 6) {
      setMessage("Please enter a valid email");
      setLoading(false);
      return;
    } else if (password.length < 8) {
      setMessage("Password must be 8 characters minimum");
      setLoading(false);
      return;
    } else if (password !== passwordConfirm && password !== "") {
      setMessage("Passwords must match");
      setLoading(false);
      return;
    }
    const success = await auth.signUp(email, password);
    if (success) {
      // navigate("/GetStarted");
    } else {
      setMessage("Failed to sign in");
    }
    setLoading(false);
  };

  const handleNavigationToLogin = () => {
    navigate("/Login");
  };

  return (
    <div className="gettingIn">
      {message != null && <AlertComponent text={message} type="error" />}
      <div className="wrapper">
        <img
          className="logo"
          src="https://honeycomb.ai/images/Logo-Color.svg"
          alt="Logo of Honeycomb"
        />
        <p className="text">Register your restaurant</p>
        <DefaultInput
          placeholder="email"
          type="email"
          inputValue={email}
          maxLength={255}
          required={true}
          onChange={(event) => setEmail(event.target.value)}
        />
        <DefaultInput
          placeholder="password"
          type="password"
          inputValue={password}
          maxLength={255}
          required={true}
          onChange={(event) => setPassword(event.target.value)}
        />
        <DefaultInput
          placeholder="confirm password"
          type="password"
          inputValue={passwordConfirm}
          maxLength={255}
          required={true}
          onChange={(event) => setPasswordConfirm(event.target.value)}
        />
        <DefaultButton
          text={"Sign Up"}
          classNameProp={"cyan"}
          onClick={handleSignUp}
          loading={loading}
        />
      </div>
      <div className="wrapper">
        <p className="text">
          Already have your <br /> restaurant registered?
        </p>
        <DefaultButton
          onClick={() => handleNavigationToLogin()}
          text={"Log In"}
          classNameProp={"cyan"}
        />
      </div>
    </div>
  );
};

export default Signup;
