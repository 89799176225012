import { useRecoilState, useResetRecoilState } from "recoil";
import { userState } from "./AuthAtoms";
import { login, signup, getUserInfo } from "../../Managers";
import { locationRouteState, locationsState, submitMenuState } from "../Atoms";

const useAuth = () => {
  const [user, setUser] = useRecoilState(userState);
  const [locations, setLocations] = useRecoilState(locationsState);
  const locationRouteReset = useResetRecoilState(locationRouteState);
  const menuLinksReset = useResetRecoilState(submitMenuState);

  const signIn = async (email, password) => {
    const token = await login(email, password);
    if (!token) return null;
    const clients = await getClients(token);
    if (!clients) return null;
    const userPayload = {
      email: email,
      token: token,
      inOnboarding: clients.length > 0 ? false : true,
    };
    setUser(userPayload);
    localStorage.setItem("user", JSON.stringify(userPayload));
    return clients;
  };

  const signUp = async (email, password) => {
    const token = await signup(email, password);
    if (token) {
      const userPayload = {
        email: email,
        token: token,
        inOnboarding: true,
      };
      setUser(userPayload);
      localStorage.setItem("user", JSON.stringify(userPayload));
      return true;
    } else {
      return false;
    }
  };

  const signOut = () => {
    locationRouteReset();
    menuLinksReset();
    setLocations([]);
    setUser(null);
    localStorage.removeItem("clients");
    localStorage.removeItem("user");
  };

  const getToken = () => {
    const token = user?.token;
    if (!token) return signOut();
    let exp;
    try {
      exp = JSON.parse(window.atob(token.split(".")[1]))?.exp;
    } catch (error) {
      return signOut();
    }
    // check if token is expired
    if (!exp || exp * 1000 < Date.now()) {
      return signOut();
    } else {
      return token;
    }
  };

  const inOnboarding = () => {
    const inOnboarding = user?.inOnboarding;
    return inOnboarding;
  };

  const completeOnboarding = () => {
    const userPayload = {
      ...user,
      inOnboarding: false,
    };
    setUser(userPayload);
    localStorage.setItem("user", JSON.stringify(userPayload));
  };

  const getClients = async (token = getToken()) => {
    const clients = await getUserInfo(token);
    if (!clients) return null;
    setLocations(clients);
    return clients;
  };

  return {
    user,
    signIn,
    signUp,
    signOut,
    getToken,
    getClients,
    inOnboarding,
    completeOnboarding,
  };
};

export default useAuth;
