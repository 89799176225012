import React from "react";
import "./ListItem.css";
import DefaultText from "./DefaultText";

const ListItem = ({ text, type, src, alt }) => {
  let list;
  if (type == "img") {
    list = (
      <li className="homeListItem">
        <img src={src} alt={alt} className="listItemImage" />
        <DefaultText classNameProp={"s14 listItemTextImg"}>{text}</DefaultText>
      </li>
    );
  } else {
    list = (
      <li className="homeListItem">
        <div className="listItemCircle"></div>
        <DefaultText classNameProp={"s14 w6 listItemText"}>{text}</DefaultText>
      </li>
    );
  }
  return list;
};

ListItem.defaultProps = {
  alt: "List image",
};

export default ListItem;
