import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import DefaultButton from "../Components/DefaultButton";
import DefaultText from "../Components/DefaultText";
import ListItem from "../Components/ListItem";
import DefaultTitle from "../Components/DefaultTitle";
import LocationBox from "../Components/LocationBox";
import "./Dashboard.css";
import { locationRouteState, locationsState } from "../States/Atoms";
import Modal from "../Components/Modal";
import ReminderBox from "../Components/ReminderBox";
import useAuth from "../States/Auth/AuthHooks";

const Dashboard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const resetLocations = useResetRecoilState(locationRouteState);
  const locations = useRecoilValue(locationsState);
  const [showModal, setShowModal] = useState(false);
  const auth = useAuth();

  const navigateToLocations = () => {
    navigate("/Home/Locations");
  };

  const navigateAddLocation = () => {
    resetLocations();
    navigate("/AddLocation/Details/Basic", { state: { hasLocations: true } });
  };

  const navigateToRestaurant = (index) => {
    navigate(`/Home/${index}`);
  };

  useEffect(() => {
    if (location.state?.firstVisit) {
      setShowModal(true);
    }
  }, []);

  useEffect(() => {
    auth.getClients();
  }, []);

  return (
    <>
      <div className="dashboard">
        <div className="locationWrapper">
          <div>
            <DefaultTitle type={3} classNameProp={"s18 w6 grayText mb12"}>
              Select A Location
            </DefaultTitle>
            <DefaultText classNameProp={"s16 mb12"}>
              <>
                Select to
                <br />
                <strong>
                  Verify Menu, Edit Menu and Edit Restaurant Details.
                </strong>
              </>
            </DefaultText>
            <div className="dashboardLocationsContainer mt20 mb20">
              {locations.slice(0, 2).map((restaurant, index) => {
                return (
                  <LocationBox
                    key={index}
                    src={restaurant.restaurantImage}
                    restaurantName={restaurant.restaurantName}
                    restaurantLocation={restaurant.address}
                    verified={restaurant.isVerified}
                    published={restaurant.isVerified}
                    active={true}
                    onClick={() => navigateToRestaurant(index)}
                  />
                );
              })}
              <LocationBox active={false} onClick={navigateAddLocation} />
            </div>
          </div>
          <DefaultButton
            text={"show all locations"}
            classNameProp={"purple width240 alignCenter s14 w7"}
            onClick={navigateToLocations}
          />
        </div>
        <div className="dashboardRight">
          <div className="gap20Column">
            <DefaultTitle type={2} classNameProp={"s20 w7 grayText width360"}>
              How many people viewed all of your menus this month?
            </DefaultTitle>
            <div className="remindersTutorials">
              <ReminderBox
                src={"../images/posted.svg"}
                alt={
                  "Young boy is waiting for the sand in the hourglass to fill up"
                }
                title="Have you posted your menu yet"
                desc="If you have finished posting your menu, its views and
                      analytics will appear here."
              />
              <ReminderBox
                src={"../images/remember.svg"}
                alt={
                  "Redheaded guy is surprised that the list in his hands is empty"
                }
                title="Don’t forget to verify your menu."
                desc="Your menu items will only be visible after you verify your
                menu. Verify them after your menu finishes processing."
              />
            </div>
          </div>
          <div className="remindersTutorials">
            <div className="contentBoxWrapper gap20">
              <DefaultTitle type={3} classNameProp={"w7 s18"}>
                Remember To
              </DefaultTitle>
              <ul className="listItemContainer">
                <ListItem text="Add all your Locations." />
                <ListItem text="Verify all your menu items once they are processed and ready." />
                <ListItem text="Post the menu link on your website for everyone to see." />
                <ListItem text="Share your menu link on social media, and promote it." />
                <ListItem text="Check back and see analytics on your location." />
              </ul>
            </div>
            <div className="contentBoxWrapper gap20">
              <DefaultTitle type={3} classNameProp={"w7 s18"}>
                Tutorials
              </DefaultTitle>
              <ul className="listItemContainer gap15">
                <ListItem
                  type="img"
                  src={"/images/video.svg"}
                  text={
                    <>
                      <strong>Video Tutorial:</strong> The dashboard and its
                      basics.
                    </>
                  }
                />
                <ListItem
                  type="img"
                  src={"/images/written.svg"}
                  text={
                    <>
                      <strong>Written Tutorial:</strong> How to publish your
                      menu everywhere?
                    </>
                  }
                />
                <ListItem
                  type="img"
                  src={"/images/blog.svg"}
                  text={
                    <>
                      <strong>Blog:</strong> Update your menu, be up-to date,
                      secret to keeping customers.
                    </>
                  }
                />
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showModal} setShowToggle={setShowModal}>
        <div className="modalContent">
          <div className="modalTitle">
            <DefaultTitle type={3} classNameProp={"s18 w6 grayText"}>
              Welcome to the Dashboard
            </DefaultTitle>
          </div>
          <div className="modalText">
            <DefaultText classNameProp={"s16 mb12"}>
              <>
                <strong>You are now ready to start posting your menu.</strong>
              </>
            </DefaultText>
            <DefaultText classNameProp={"s16 mb12"}>
              <>
                <strong>
                  You can add your locations and menu items once you have
                  finished processing your menu.
                </strong>
              </>
            </DefaultText>
            <DefaultText classNameProp={"s16 mb12"}>
              <>
                <strong>
                  You can also check the analytics of your location.
                </strong>
              </>
            </DefaultText>
          </div>
          <div className="modalButton">
            <DefaultButton
              text={"Got it!"}
              classNameProp={"purple width240 alignCenter s14 w7"}
              onClick={() => setShowModal(false)}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Dashboard;
