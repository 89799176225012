import React, { useState } from "react";
import { useRecoilValue } from "recoil";
import DefaultButton from "../Components/DefaultButton";
import AlertComponent from "../Components/AlertComponent";
import "./PostRestaurant.css";
import DefaultText from "../Components/DefaultText";
import Toggle from "../Components/Toggle";
import QRCode from "react-qr-code";
import Modal from "../Components/Modal";
import { locationRouteState } from "../States/Atoms";
import DefaultTitle from "../Components/DefaultTitle";
import DietToken from "../Components/DietToken";
import { supportedDiets } from "../Assets/StaticData";
import { deepClone } from "../config/constants";

const PostRestaurant = () => {
  const locationRoute = useRecoilValue(locationRouteState);
  const [copySuccess, setCopySuccess] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [qrLink, setQrLink] = useState(
    `app.honeycomb.ai/${locationRoute.slugName}`
  );
  const [supportedDiet, setSupportedDiet] = useState(() => {
    const copy = deepClone(supportedDiets);
    return copy;
  });

  const downloadQR = () => {
    const svg = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${window.btoa(svgData)}`;
  };

  const handleClickDiet = (id, bool) => {
    const newData = deepClone(supportedDiet);
    newData[id].checked = !bool;
    setSupportedDiet(newData);
  };

  const handleOpenLink = () => {
    window.open(`http://www.${qrLink}`, "_blank");
  };

  return (
    <>
      <div className="postRestaurant">
        {locationRoute.isVerified ? (
          <AlertComponent
            classNameProp={"mWidth450"}
            type="success"
            text="Your food menu is ready to be published. The honeycomb menu only shows restaurant diet info."
          />
        ) : (
          <AlertComponent
            classNameProp={"mWidth450"}
            type="error"
            text="Your food menu is processing at the moment. The honeycomb menu only shows restaurant diet info."
          />
        )}
        <div className="seperateLeftRight">
          <div className="postRestaurantLeft">
            <div className="postRestwrapper menuLink">
              <div className="restaurantLink">
                <h3 className="menuPostTitle">Menu Link:</h3>
                <DefaultText classNameProp={"w4"}>
                  Honeycomb menu can be published as a link, which you can use
                  with a button on your website, use it with a QR code in store
                  or share it through your social media.
                </DefaultText>
                <div className="copyLink">
                  <img src={"/images/linkTag.svg"} />
                  {qrLink}
                </div>
                <div className="postBtnContainer">
                  <DefaultButton
                    text="Open Menu"
                    classNameProp={"purple s12 w7 mont postbtn flex1"}
                    onClick={handleOpenLink}
                  />
                  <DefaultButton
                    text={copySuccess ? "Copied" : "Copy Link"}
                    onClick={() => {
                      navigator.clipboard
                        .writeText(qrLink)
                        .then(() => {
                          setCopySuccess(true);
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    }}
                    classNameProp={"purple s12 w7 mont postbtn flex1"}
                  />
                </div>
              </div>

              <div className="restaurantQR">
                <div
                  style={{
                    height: "auto",
                    margin: "5px auto",
                    maxWidth: 150,
                    width: "100%",
                  }}
                >
                  <QRCode
                    id="QRCode"
                    size={256}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={qrLink}
                    title="QR Code"
                    viewBox={`0 0 256 256`}
                  />
                </div>
                <DefaultButton
                  text="Download QR Code"
                  classNameProp={"purple s12 w7 mont postbtn width180"}
                  onClick={downloadQR}
                />
              </div>
            </div>
          </div>

          <div className="postRestaurantRight">
            <div className="postRestwrapper menuPreference">
              <div className="toggleElements">
                <h3 className="menuPostTitle">Menu Preferences:</h3>
                <div className="toggleContainer">
                  <DefaultText classNameProp={"s14 w7 toggleText"}>
                    Price:
                  </DefaultText>
                  <Toggle text1="Show" text2="Hide" />
                </div>
                <div className="toggleContainer">
                  <DefaultText classNameProp={"s14 w7 toggleText"}>
                    Only Verified Items:
                  </DefaultText>
                  <Toggle
                    text1="Only Verified"
                    text2="Show All"
                    disabled={true}
                  />
                </div>
              </div>
              {/* <div className="preferenceSub"> // Will come in later
            <h4 className="postSubTitle">Link Ordering Platforms:</h4>
            <DefaultText classNameProp="w5">
              You can also link, ordering, delivery, or reservation platforms to
              your honyecomb menu.
            </DefaultText>
            <DefaultButton
              text={"Start Linking"}
              classNameProp={"purple s12 w7 mont postbtn width180 alignCenter"}
            />
          </div> */}
              <div className="preferenceSub">
                <h4 className="postSubTitle">
                  Change shown allergens & diets:
                </h4>
                <DefaultText classNameProp="w5">
                  You might not want to show some diets & allergens that don’t
                  need to be shown. For example: A 100% Vegan restaurant might
                  not want to show Paleo as a filter option .
                </DefaultText>
                <DefaultButton
                  text={"Edit Shown Diets"}
                  classNameProp={
                    "purple s12 w7 mont width240 postbtn alignCenter"
                  }
                  onClick={() => setShowModal(true)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        setShowToggle={setShowModal}
        classNameProp={"changeDietModal"}
      >
        <DefaultTitle classNameProp={"s20 w7"}>
          Change shown diets:
        </DefaultTitle>
        <div className="flexRow justifyBetween">
          <DefaultText classNameProp={"s14 grayText mWidth450"}>
            Click to select allergens or diets you don't want shown as an option
            for customers to select as a filter in your online menu.
          </DefaultText>
          <DefaultButton
            text="save changes"
            classNameProp={"cyan width180 s14 w7 montAlt"}
            onClick={() => setShowModal(false)}
          />
        </div>
        <div className="dotText">
          <div className="dotDiv"></div>
          <DefaultText classNameProp={"s14 w7 grayText"}>
            Not Shown As A Filter Option
          </DefaultText>
        </div>
        <div className="dietTokenContainer">
          {Object.keys(supportedDiet).map((key) => {
            const diet = supportedDiet[key];
            return (
              <DietToken
                key={diet.id}
                title={diet.id}
                classNameProp={diet.checked ? "activeDietToken" : ""}
                src={`/images/DietTokenIcons/${diet.image}`}
                handleClick={() => handleClickDiet(diet.id, diet.checked)}
              />
            );
          })}
        </div>
      </Modal>
    </>
  );
};

export default PostRestaurant;
