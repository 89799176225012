import React, { useEffect, useState } from "react";
import "./HomeSubBar.css";
import DefaultText from "../DefaultText";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { dishesChangedState, sortedDishsSelector } from "../../States/Atoms";
import DefaultButton from "../DefaultButton";
import BackButton from "../BackButton";
import { stripItems } from "../../Util/DishItemHelpers";

const HomeSubBar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [dishesChanged, setDishesChanged] = useRecoilState(dishesChangedState);
  const dishs = useRecoilValue(sortedDishsSelector);
  const [loading, setLoading] = useState(false);
  const [publishData, setPublishData] = useState({ show: false, message: "" });
  const [titleName, setTitleName] = useState("Home");
  const [backButton, setBackButton] = useState(false);

  const handlePublish = () => {
    setLoading(true);
    setPublishData({ show: false, message: " Publishing..." });
    const items = stripItems(dishs);
    console.log(items);
    setTimeout(() => {
      // until the backend is ready
      setDishesChanged(false);
      setLoading(false);
      setPublishData({
        show: true,
        message: ` ${new Date().toLocaleString()}`,
      });
    }, 2000);
  };

  useEffect(() => {
    if (location.pathname.includes("/Menu")) {
      setTitleName("Menu");
      setBackButton(true);
      setDishesChanged(false);
    } else if (location.pathname.includes("/Locations")) {
      setTitleName("Home - Locations");
      setBackButton(true);
    } else if (location.pathname.includes("/Home/")) {
      setTitleName("Restaurant Overview");
      setBackButton(true);
    } else {
      setTitleName("Home");
      setBackButton(false);
    }
  }, [location.pathname]);

  const handleNavigate = () => {
    navigate(-1);
  };

  if (
    location.pathname.includes("/Edit") ||
    location.pathname.includes("/Post")
  ) {
    return null;
  }
  return (
    <div className="homeSubBar">
      <div className="homeSubBarContainer">
        <div className="headerContainer">
          {backButton && <BackButton onClick={handleNavigate} />}
          <DefaultText classNameProp={"s18 w7 blackText"}>
            {titleName}
          </DefaultText>
        </div>
        {titleName === "Menu" && (
          <div className="headerContainer">
            <div className="flexRow gap25 alignItemCenter">
              <DefaultText classNameProp={"s14"}>
                <span className="grayText w4">
                  Last changes {publishData.message} minutes ago.
                </span>
                <span className="cyanText w5">
                  {" "}
                  Changes {dishesChanged ? "saved" : "not saved"},
                  {publishData.show ? " published" : " not published"}.
                </span>
              </DefaultText>

              <DefaultButton
                disabled={!dishesChanged}
                loading={loading}
                classNameProp={"purple s14 w7"}
                text="publish changes"
                onClick={handlePublish}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeSubBar;
