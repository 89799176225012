import React from "react";
import { useNavigate } from "react-router-dom";
import DefaultButton from "../Components/DefaultButton";
import DefaultInput from "../Components/DefaultInput";
import "./Registration.css";

const ForgotPassword = () => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/Login");
  };
  return (
    <div className="gettingIn">
      <div className="wrapper">
        <img
          className="logo"
          src="https://honeycomb.ai/images/Logo-Color.svg"
          alt="Logo of Honeycomb"
        />
        <p className="text">
          Enter your email for <br /> password recovery.
        </p>
        <DefaultInput
          placeholder="email"
          type="email"
          maxLength={255}
          required={true}
          onChange={() => null}
        />
        <DefaultButton
          onClick={() => null}
          text={"submit"}
          classNameProp={"cyan"}
        />
        <DefaultButton
          onClick={() => null}
          text={"I don't know my email either"}
          classNameProp={"skeleton"}
        />
      </div>
      <div className="wrapper">
        <p className="text">Remember your password?</p>
        <DefaultButton
          onClick={() => handleNavigate()}
          text={"Sign In"}
          classNameProp={"cyan"}
        />
      </div>
    </div>
  );
};

export default ForgotPassword;
