import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import HomeNavBar from "../Components/Navbars/HomeNavBar";
import HomeSubBar from "../Components/Navbars/HomeSubBar";
import { loadedState } from "../States/Auth/AuthAtoms";
const HomeLayout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loaded, setIsLoaded] = useRecoilState(loadedState);

  const beforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = "Confirm leaving the page?";
    return event.returnValue;
  };

  useEffect(() => {
    if (location.pathname !== "/Home") {
      if (!loaded) navigate("/Home");
    }
  }, [location]);

  useEffect(() => {
    setIsLoaded(true);
    window.addEventListener("beforeunload", beforeUnload);
    return () => {
      window.removeEventListener("beforeunload", beforeUnload);
    };
  }, []);

  return (
    <div>
      <HomeNavBar />
      <HomeSubBar />
      <Outlet />
    </div>
  );
};

export default HomeLayout;
