import React from "react";
import DefaultText from "./DefaultText";
import CardHeaderText from "./CardHeaderText";
import "./ContentBox.css";

const ContentBox = ({ title, desc, children, classNameProp }) => {
  return (
    <div className={`contentBoxWrapper ${classNameProp}`}>
      <CardHeaderText>{title}</CardHeaderText>
      <DefaultText>{desc}</DefaultText>
      {children}
    </div>
  );
};

export default ContentBox;
