import React, { useState } from "react";
import "./Toggle.css";

const Toggle = ({ onClick, disabled = false, text1 = " ", text2 = " " }) => {
  const [isToggled, setIsToggled] = useState(true);
  const toggle = () => setIsToggled(!isToggled);
  return (
    <div>
      <button
        disabled={disabled}
        className={`toggleButton toggleLeft ${isToggled ? "selectedTb" : ""}`}
        style={disabled ? { opacity: 0.7 } : null}
        onClick={toggle}
      >
        {text1}
      </button>
      <button
        disabled={disabled}
        className={`toggleButton toggleRight ${
          !isToggled ? "selectedTb" : ""
        } `}
        onClick={toggle}
        style={disabled ? { opacity: 0.7 } : null}
      >
        {text2}
      </button>
    </div>
  );
};

export default Toggle;
