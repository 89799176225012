import React, { useState } from "react";
import "./ContentBox.css";
import "./Modal.css";

const Modal = ({ show, children, classNameProp, setShowToggle }) => {
  const clickStyle = {
    position: "absolute",
    zIndex: "-1",
    flex: 1,
    display: "flex",
    width: "100vw",
    height: "100vh",
  };
  if (!show) {
    return null;
  }
  return (
    <div className="modal">
      <div className={`contentBoxWrapper modalContentBox ${classNameProp}`}>
        {children}
      </div>
      <label
        style={clickStyle}
        onClick={() => {
          setShowToggle(false);
        }}
      ></label>
    </div>
  );
};

export default Modal;
