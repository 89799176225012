import React, { createRef, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import DefaultInput from "../Components/DefaultInput";
import MenuItemCard from "../Components/MenuItemCard";
import AllergenToken from "../Components/AllergenToken";
import { getMenuItems } from "../Managers/WifiManager";
import {
  locationsStateSelectorFamily,
  MenuSubtextSelector,
  rawMenuRequestState,
  SelectedItemsStateSelector,
  sortableFieldState,
  sortedDishsSelector,
} from "../States/Atoms";
import useAuth from "../States/Auth/AuthHooks";
import NestedList from "./NestedList";
import EditButton from "../Components/EditButton";
import "./RestaurantMenu.css";
import DefaultTitle from "../Components/DefaultTitle";
import DefaultText from "../Components/DefaultText";
import AlertComponent from "../Components/AlertComponent";
import { buildArrayFromItems } from "../Util/DishItemHelpers";
import { deepClone } from "../config/constants";
const FADE_OUT_DURATION = 3000;

const RestaurantMenu = () => {
  const auth = useAuth();
  const params = useParams();
  const [menuLoading, setMenuLoading] = useState(true);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const restaurant = useRecoilValue(
    locationsStateSelectorFamily(params?.index)
  );
  const setRawRequest = useSetRecoilState(rawMenuRequestState);
  const [sortable, setSortable] = useRecoilState(sortableFieldState);
  const [sortedDishs, setSortedDishs] = useRecoilState(sortedDishsSelector);
  const [selectedItems, setSelectedItems] = useRecoilState(
    SelectedItemsStateSelector
  );
  const itemRefs = useRef({});
  const selectedItem = useRef(null);
  const subSections = useRecoilValue(MenuSubtextSelector);
  const navigate = useNavigate();

  const scrollItemToView = (itemID) => {
    if (itemRefs.current[itemID]) {
      itemRefs.current[itemID].current?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
      selectedItem.current = itemID;
    }
  };

  const createItemRef = (itemID, firstCreate) => {
    if (firstCreate) {
      itemRefs.current = {};
    }
    if (itemID) {
      itemRefs.current[itemID] = createRef();
      return itemRefs.current[itemID];
    }
  };

  const cleanItems = (rawRequest) => {
    const arr = buildArrayFromItems(rawRequest.items);
    setSortedDishs(arr);
    setMenuLoading(false);
  };

  const getDishs = async () => {
    setMenuLoading(true);
    const tokens = auth.getToken();
    const dishsResponse = await getMenuItems(tokens, restaurant.restaurantID);
    if (
      !dishsResponse ||
      !dishsResponse.items ||
      !Array.isArray(dishsResponse.items)
    ) {
      setMenuLoading(false);
      return;
    }
    setRawRequest(deepClone(dishsResponse));
    cleanItems(dishsResponse);
  };

  const setAlertWithMessage = (message) => {
    setAlert(true);
    setAlertMessage(message);
  };

  const handleNavigateAddItem = () => {
    navigate("AddItem");
  };

  useEffect(() => {
    getDishs();
  }, []);

  useEffect(() => {
    let alertTimeout;
    if (alert) {
      alertTimeout = setTimeout(() => {
        setAlert(false);
      }, FADE_OUT_DURATION);
    }
    return () => {
      clearTimeout(alertTimeout);
    };
  }, [alert]);

  useEffect(() => {
    if (
      selectedItems.length > 0 &&
      selectedItem.current &&
      itemRefs.current[selectedItem.current]
    ) {
      itemRefs.current?.[selectedItem.current].current?.scrollIntoView?.({
        behavior: "smooth",
        block: "start",
      });
      selectedItem.current = null;
    }
  }, [selectedItems]);

  //Restaurnt menu editing with drag and drop component
  if (menuLoading) {
    return <div>Loading...</div>;
  } else {
    return (
      <div className="restaurantMenu">
        <div className="dragableComponents">
          <DefaultTitle type={4} classNameProp={`s14 w7 blackText menuPadding`}>
            Menu
          </DefaultTitle>
          <div className="searchInputContainer">
            <DefaultInput
              classNameProp={"s10 w4 grayText defaultInputSearch"}
              placeholder={"search menu, sections, dishes........."}
              inputValue={sortable}
              onChange={(event) => setSortable(event.target.value)}
            />
          </div>
          <div className="scrollableNestedList">
            <NestedList
              sortedItems={sortedDishs}
              onChange={setSortedDishs}
              setAlertWithMessage={setAlertWithMessage}
              scrollToView={scrollItemToView}
            />
          </div>
        </div>
        <div className="restaurantMenuRight">
          <div className="flexRow justifyBetween">
            <div
              className="flexRow alignCenter gap10 click"
              onClick={handleNavigateAddItem}
            >
              <button className="burgerBox click">
                <div className="line" />
                <div className="line" />
                <div className="line" />
              </button>
              <DefaultText classNameProp={"s16 w6 blackText alignCenter"}>
                Add Item
              </DefaultText>
            </div>
            {
              //Alert component for adding items
              alert && (
                <AlertComponent
                  type="error"
                  text={alertMessage}
                  classNameProp={"width200"}
                  fade={true}
                  setFade={setAlert}
                  fadeDuration={FADE_OUT_DURATION}
                />
              )
            }
            {restaurant.isVerfied ? (
              <AlertComponent
                type="success"
                text={"Your restaurant is verified!"}
                classNameProp={"width480"}
              />
            ) : (
              <AlertComponent
                type="error"
                text={
                  "Your food menu is not completely verified. Only verified information will be shown in the online menu."
                }
                classNameProp={"width480"}
              />
            )}
          </div>
          <div className="wholeMenu">
            <div className="flexColumn gap15">
              <div className="flexRow justifyBetween">
                <div className="flexColumn gap5">
                  <DefaultTitle type={1} classNameProp={"s24 w6 blackText"}>
                    {selectedItems?.[0] ? selectedItems[0]?.itemMenu : "Menu"}
                  </DefaultTitle>
                  <DefaultText classNameProp={"s16 grayText"}>
                    {subSections?.menuSubText}
                  </DefaultText>
                </div>
                <EditButton classNameProp={"alignCenter"} text="Edit" />
              </div>
              <div className="lineHR"></div>
            </div>
            <div className="scrollableMenuItems flexColumn gap25">
              <div className="flexRow justifyBetween">
                <div className="flexColumn gap5">
                  <DefaultTitle type={2} classNameProp={"s18 w6 blackText"}>
                    {selectedItems?.[0]
                      ? selectedItems[0]?.itemSection
                      : "Section"}
                  </DefaultTitle>
                  <DefaultText classNameProp={"s16 grayText"}>
                    {subSections?.sectionSubText}
                  </DefaultText>
                </div>
                <EditButton classNameProp={"alignCenter"} text="Edit Section" />
              </div>
              <div className="flexColumn gap50">
                {selectedItems?.map((item, index) => {
                  return (
                    <MenuItemCard
                      key={"itemCard" + index}
                      item={item}
                      itemID={item.itemID}
                      name={item?.itemName}
                      price={item?.itemPrice ? item?.itemPrice : ""}
                      desc={item?.itemDescription || ""}
                      verified={item?.itemVerified}
                      ref={createItemRef(item.itemID, index === 0)}
                      allergensContained={
                        <>
                          {item != null &&
                            item?.itemContainsVector?.map((allergen, index) => {
                              return (
                                <AllergenToken
                                  key={"contained" + index}
                                  allergen={allergen}
                                  contains={true}
                                />
                              );
                            })}
                          <div></div>
                        </>
                      }
                      allergensModifiable={
                        <>
                          {item != null &&
                            item?.itemModifiableVector?.map(
                              (allergen, index) => {
                                return (
                                  <AllergenToken
                                    key={"modifiable" + index}
                                    modifiable={true}
                                    allergen={allergen}
                                  />
                                );
                              }
                            )}
                        </>
                      }
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default RestaurantMenu;
