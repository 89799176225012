import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import LocationsNavBar from "../Components/Navbars/LocationsNavBar";
import LocationsSubBar from "../Components/Navbars/LocationsSubBar";
import { loadedState } from "../States/Auth/AuthAtoms";
import "./LocationsLayout.css";

const LocationsLayout = ({ editable = false }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loaded, setIsLoaded] = useRecoilState(loadedState);

  const beforeUnload = (event) => {
    event.preventDefault();
    event.returnValue = "Confirm leaving the page?";
    return event.returnValue;
  };

  useEffect(() => {
    if (location.pathname !== "/AddLocation/Details/Basic") {
      if (!loaded) navigate("/Home");
    }
  }, [location]);

  useEffect(() => {
    setIsLoaded(true);
    window.addEventListener("beforeunload", beforeUnload);
    return () => {
      window.removeEventListener("beforeunload", beforeUnload);
    };
  }, []);

  return (
    <div className="locationsLayout">
      <LocationsNavBar />
      <LocationsSubBar editable={editable} />
      <Outlet />
    </div>
  );
};

export default LocationsLayout;
