import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { establishmentPracticesData } from "../Assets/StaticData";
import DefaultButton from "../Components/DefaultButton";
import DefaultText from "../Components/DefaultText";
import DefaultTitle from "../Components/DefaultTitle";
import DietaryInfoCard from "../Components/DietaryInfoCard";
import ReminderBox from "../Components/ReminderBox";
import VisualCommandCard from "../Components/VisualCommandCard";
import {
  locationRouteState,
  locationsState,
  locationsStateSelectorFamily,
} from "../States/Atoms";
import "./RestaurantOverview.css";

const RestaurantOverview = () => {
  const params = useParams();
  const navigate = useNavigate();
  const locations = useRecoilValue(locationsState);
  const restaurant = useRecoilValue(
    locationsStateSelectorFamily(params?.index)
  );
  const [locationRoute, setLocationRoute] = useRecoilState(locationRouteState);

  const handleNavigateToMenu = () => {
    navigate(`/Home/${params?.index}/Menu`);
  };

  const handleNavigateToPost = () => {
    const locationToEdit = locations.find(
      (rest) => rest.restaurantID === restaurant.restaurantID
    );
    setLocationRoute({
      ...locationToEdit,
      restaurantID: restaurant.restaurantID,
    });
    navigate(`/Home/${params?.index}/Post`);
  };

  const handleNavigateToEdit = () => {
    const locationToEdit = locations.find(
      (rest) => rest.restaurantID === restaurant.restaurantID
    );
    setLocationRoute({
      ...locationToEdit,
      restaurantID: restaurant.restaurantID,
    });
    navigate(`/Home/${params?.index}/Edit/Details/Basic`);
  };

  return (
    <div className="restaurantOverview">
      <div className="restaurantTopTitle">
        <DefaultTitle classNameProp={"s18 w6"}>
          Restaurant:{" "}
          <span className="cyanText">{`${restaurant?.restaurantName} - ${restaurant?.neighbourhood}`}</span>
        </DefaultTitle>
        <DefaultTitle classNameProp={"s18 w6"}>
          Last Verified:{" "}
          <span className="blackText">
            {restaurant?.isVerified ? "Verified" : "Not Verified"}
          </span>
        </DefaultTitle>
        <DefaultTitle classNameProp={"s18 blackText"}>
          <span className="w6">Published To Website:</span>{" "}
          <span className="w4">{}</span>
        </DefaultTitle>
      </div>
      <DefaultButton
        text="Verify menu"
        classNameProp={"purple width180 s14 w7 alignEnd"}
        onClick={handleNavigateToMenu}
      />
      <div className="restaurantDetailsContainer">
        <div className="flexColumn restaurantContentLeft">
          <ReminderBox
            src={"/images/posted.svg"}
            alt={
              "Young boy is waiting for the sand in the hourglass to fill up"
            }
            title="Have you posted your menu yet"
            desc="If you have finished posting your menu, its views and
                      analytics will appear here."
          />
          <VisualCommandCard
            onClick={handleNavigateToMenu}
            src={"/images/post.png"}
            text="Verify & Edit Menu"
          />
          <VisualCommandCard
            onClick={handleNavigateToPost}
            src={"/images/verify.png"}
            text="Post Restaurant"
          />
        </div>
        <div className="restaurantContentMiddle flexColumn">
          <div className="contentBoxWrapper restaurantInfoMiddle">
            <img className="height130 borderR5" src="/images/sample.png" />
            <div>
              <DefaultTitle type={4} classNameProp={"s14 w7 grayText"}>
                Restaurant Name:
              </DefaultTitle>
              <DefaultText classNameProp={"s14 w7"}>
                {restaurant?.restaurantName}
              </DefaultText>
            </div>
            <div>
              <DefaultTitle type={4} classNameProp={"s14 w7 grayText"}>
                Location Indicator:
              </DefaultTitle>
              <DefaultText classNameProp={"s14 w7"}>
                {restaurant?.neighbourhood}
              </DefaultText>
            </div>
            <div>
              <DefaultTitle type={4} classNameProp={"s14 w7 grayText"}>
                Address:
              </DefaultTitle>
              <DefaultText classNameProp={"s14 w7 width180"}>
                {restaurant?.address}
              </DefaultText>
            </div>
            <div>
              <DefaultTitle type={4} classNameProp={"s14 w7 grayText"}>
                Restaurant Diet Practices:
              </DefaultTitle>
              <div className="scrollableDiets">
                {Object.keys(establishmentPracticesData).map((diet, index) => {
                  if (restaurant?.establishmentPractices?.includes(diet)) {
                    return (
                      <DietaryInfoCard
                        key={index}
                        title={establishmentPracticesData[diet].name}
                        src={establishmentPracticesData[diet].image}
                        disabled={true}
                      />
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="restaurantContentRight flexColumn">
          {restaurant?.restaurantDietaryPolicy !== "" &&
            typeof restaurant?.restaurantDietaryPolicy == "string" && (
              <div className="contentBoxWrapper">
                <DefaultTitle type={3} classNameProp={"s16 w7 graytext"}>
                  Dietary Policy:
                </DefaultTitle>
                <DefaultText classNameProp={"w5 s14 grayText"}>
                  {restaurant?.restaurantDietaryPolicy}
                </DefaultText>
              </div>
            )}
          <div className="contentBoxWrapper">
            <DefaultTitle type={3} classNameProp={"s16 w7"}>
              Contact
            </DefaultTitle>
            <div className="restaurantContactInfo flexColumn">
              <DefaultText classNameProp={"s14"}>
                <span className="w7">Phone: </span>
                {restaurant?.phoneNumber}
              </DefaultText>
              <DefaultText classNameProp={"s14"}>
                <span className="w7">Website: </span>
                {restaurant?.website}
              </DefaultText>
              <DefaultText classNameProp={"s14"}>
                <span className="w7">Instagram: </span>
                {restaurant?.restaurantInstagramID}
              </DefaultText>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestaurantOverview;
