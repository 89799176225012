import React from "react";
import DefaultText from "../Components/DefaultText";
import DefaultButton from "../Components/DefaultButton";
import DefaultTitle from "../Components/DefaultTitle";
import "./Account.css";

const Account = () => {
  return (
    <div className="account">
      <div className="flexColumn gap25">
        <div className="contentBoxWrapper">
          <DefaultTitle type={2} classNameProp={"w6 s18"}>
            Account Primary
          </DefaultTitle>
          <DefaultButton
            classNameProp={"s14 w6 whiteText rad5 blue paddingV15"}
            text={"email"}
          ></DefaultButton>
        </div>
        <div className="contentBoxWrapper paddingV30">
          <DefaultText classNameProp={"w6 s14 grayText alignCenter"}>
            <a href="https://honeycomb.ai/privacy-policy.html">
              Privacy Policy
            </a>
          </DefaultText>

          <DefaultText classNameProp={"w6 s14 grayText alignCenter"}>
            <a href="https://honeycomb.ai/terms-of-use.html">
              Terms & Conditions
            </a>
          </DefaultText>
        </div>
      </div>
      <div className="contentBoxWrapper gap25">
        <div className="flexColumn gap15">
          <DefaultTitle type={2} classNameProp={"w6 s18"}>
            Current Plan
          </DefaultTitle>
          <DefaultButton
            classNameProp={"s14 w6 whiteText rad5 blue paddingV25"}
            text={"Standard Plan"}
          ></DefaultButton>
        </div>
        <ul className="flexColumn gap15">
          <li>
            <DefaultText classNameProp={"w6 s14 grayText"}>
              Cost: $0 / year
            </DefaultText>
          </li>
          <li>
            <DefaultText classNameProp={"w6 s14 grayText"}>
              Locations: 10 Locations
            </DefaultText>
          </li>
          <li>
            <DefaultText classNameProp={"w6 s14 grayText"}>
              Analytics: Basic
            </DefaultText>
          </li>
          <li>
            <DefaultText classNameProp={"w6 s14 grayText"}>
              Concierge Service: None
            </DefaultText>
          </li>
          <li>
            <DefaultText classNameProp={"w6 s14 grayText"}>
              Term: Indefinite
            </DefaultText>
          </li>
        </ul>
      </div>
      <div className="flexColumn gap25">
        <div className="contentBoxWrapper">
          <DefaultTitle type={2} classNameProp={"w6 s18"}>
            Change Password
          </DefaultTitle>
          <DefaultButton
            classNameProp={
              "borderButton width240 alignCenter s14 w6 paddingV12"
            }
            text={"Change Password"}
          />
        </div>
        <div className="contentBoxWrapper gap25">
          <DefaultTitle type={2} classNameProp={"w6 s18"}>
            Contact Honeycomb?
          </DefaultTitle>
          <div className="flexColumn gap10">
            <DefaultTitle classNameProp={"w6 s16 grayText"} type={3}>
              Email:
            </DefaultTitle>
            <DefaultButton
              classNameProp={"borderButton s14 w6 rad5 paddingV12"}
              text={"chef@honeycomb.ai"}
            />
          </div>
          <div className="flexColumn gap10">
            <DefaultTitle classNameProp={"w6 s16 grayText"} type={3}>
              Facebook:
            </DefaultTitle>
            <DefaultButton
              classNameProp={"borderButton s14 w7 rad5 paddingV12"}
              text={"honeycomb.app"}
            />
          </div>
          <DefaultButton
            classNameProp={"cyan s14 w7 paddingV15 width280 alignCenter"}
            text={"Book An Appointment"}
          />
        </div>
      </div>
    </div>
  );
};

export default Account;
