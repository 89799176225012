import React, { useEffect, useState } from "react";
import { NavLink, Outlet, useLocation, useNavigate } from "react-router-dom";
import "./RestaurantDetailsNavBar.css";

const RestaurantDetailsNavBar = () => {
  const [basicActive, setBasicActive] = useState(true);
  const [infoActive, setInfoActive] = useState(false);
  const [policyActive, setPolicyActive] = useState(false);
  const [contactActive, setContactActive] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  // This styling will be applied to a <NavLink> when the
  // route that it links to is currently selected.
  let activeStyle = {
    color: "#464F7A",
  };

  useEffect(() => {
    if (location.pathname.includes("/Edit")) {
      setBasicActive(true);
      setInfoActive(true);
      setPolicyActive(true);
      setContactActive(true);
      return;
    }
    if (location.pathname.includes("Details/Basic")) {
      setBasicActive(true);
    } else if (location.pathname.includes("Details/Info")) {
      setInfoActive(true);
    } else if (location.pathname.includes("Details/Policy")) {
      setPolicyActive(true);
    } else if (location.pathname.includes("Details/Contact")) {
      setContactActive(true);
    }
  }, [location]);

  const handleClick = (e, to) => {
    e.preventDefault();
    if (to === "Basic" && basicActive) {
      navigate(`Basic`);
    } else if (to === "Info" && infoActive) {
      navigate(`Info`);
    } else if (to === "Policy" && policyActive) {
      navigate(`Policy`);
    } else if (to === "Contact" && contactActive) {
      navigate(`Contact`);
    }
  };

  return (
    <div className="restaurantDetails">
      <nav className="sideNav">
        <ul className="navLinks">
          <li>
            <NavLink
              onClick={(e) => handleClick(e, "Basic")}
              to="Basic"
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              Location Name & Address
            </NavLink>
          </li>
          <li>
            <NavLink
              to="Info"
              onClick={(e) => handleClick(e, "Info")}
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              Dietary Info
            </NavLink>
          </li>
          <li>
            <NavLink
              to="Policy"
              onClick={(e) => handleClick(e, "Policy")}
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              Dietary Policy
            </NavLink>
          </li>
          <li>
            <NavLink
              to="Contact"
              onClick={(e) => handleClick(e, "Contact")}
              style={({ isActive }) => (isActive ? activeStyle : undefined)}
            >
              Contact
            </NavLink>
          </li>
        </ul>
      </nav>
      <Outlet />
    </div>
  );
};

export default RestaurantDetailsNavBar;
