import React from "react";
import "./DefaultTextArea.css";

const DefaultTextArea = ({
  placeholder,
  id,
  name,
  required,
  maxLength,
  onChange,
  inputValue,
  classNameProp,
}) => {
  return (
    <textarea
      className={`defaultTextArea ${classNameProp}`}
      placeholder={placeholder}
      maxLength={maxLength}
      required={required}
      value={inputValue}
      name={name}
      id={id}
      onChange={onChange}
    />
  );
};

DefaultTextArea.defaultProps = {
  placeholder: "Enter your comment here.",
  required: true,
  maxLength: 1000,
};

export default DefaultTextArea;
