import React from "react";
import "./DefaultText.css";

const DefaultText = ({ children, classNameProp, style }) => {
  return (
    <p style={style} className={`defaultText ${classNameProp}`}>
      {children}
    </p>
  );
};

DefaultText.defaultProps = {
  classNameProp: "",
};

export default DefaultText;
