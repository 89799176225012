import React from "react";
import DefaultText from "./DefaultText";
import "./BackButton.css";

const BackButton = ({ onClick, text }) => {
  return (
    <button className="backButton" onClick={onClick}>
      <img src="/images/back.svg" alt="Back icon"></img>
      <DefaultText classNameProp={"s18 w6 grayText"}>{text}</DefaultText>
    </button>
  );
};

export default BackButton;
