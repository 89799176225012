import React from "react";
import { useNavigate } from "react-router-dom";
import DefaultButton from "../Components/DefaultButton";
import DefaultText from "../Components/DefaultText";
import "./GetStarted.css";

const GetStarted = () => {
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate("/AddLocation/Details");
  };
  return (
    <div className="getStarted">
      <h1 className="gsTitle">Welcome to Honeycomb</h1>
      <p className="gsDesc">
        Let's get started with setting up your first account
      </p>
      <div className="gsWrapper">
        <div className="gsStepsContainer">
          <div className="gsSection">
            <h2 className="gsStepNumber">1</h2>
            <div className="gsStep">
              <h2 className="gsStepTitle">Add Location</h2>
              <DefaultText>
                Set name, description, photo, and tell us what dietary practices
                you follow.
              </DefaultText>
            </div>
          </div>
          <div className="gsSection">
            <h2 className="gsStepNumber">2</h2>
            <div className="gsStep">
              <h2 className="gsStepTitle">Add Menu</h2>
              <DefaultText>
                This is the main menu for your restaurant, you can use this to
                create location specific menus.
              </DefaultText>
            </div>
          </div>
          <div className="gsSection">
            <h2 className="gsStepNumber">3</h2>
            <div className="gsStep">
              <h2 className="gsStepTitle">Post Restaurant</h2>
              <DefaultText>
                You can start posting your menu, with just the dietary
                information and no menu items while your menu is processing.
              </DefaultText>
            </div>
          </div>
        </div>

        <DefaultText classNameProp={`dashedCenterText`}>
          Wait for us to process your menu
        </DefaultText>

        <DefaultText>
          You can create more locations specific information while we process
          your menu. You can also manually enter menu information if you don’t
          want to wait on us.
        </DefaultText>

        <DefaultButton
          text={"get started"}
          onClick={handleNavigation}
          classNameProp={"cyan gsButton"}
        />
      </div>
    </div>
  );
};

export default GetStarted;
