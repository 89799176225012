export const establishmentPracticesData = {
  "Allergy Friendly": {
    id: "Allergy Friendly",
    name: "allergy friendly",
    image: "allergyFriendly.svg",
    checked: false,
  },
  "Tree Nut Allergy Friendly": {
    id: "Tree Nut Allergy Friendly",
    name: "treenut allergy friendly",
    image: "treenutAllergy.svg",
    checked: false,
  },
  "Dairy Allergy Friendly": {
    id: "Dairy Allergy Friendly",
    name: "dairy allergy friendly",
    image: "dairyAllergy.svg",
    checked: false,
  },
  "Shellfish Allergy Friendly": {
    id: "Shellfish Allergy Friendly",
    name: "shellfish allergy friendly",
    image: "shellfishAllergy.svg",
    checked: false,
  },
  "Egg Allergy Friendly": {
    id: "Egg Allergy Friendly",
    name: "egg allergy friendly",
    image: "eggAllergy.svg",
    checked: false,
  },
  "Sesame Allergy Friendly": {
    id: "Sesame Allergy Friendly",
    name: "sesame allergy friendly",
    image: "sesameAllergy.svg",
    checked: false,
  },
  "Soy Allergy Friendly": {
    id: "Soy Allergy Friendly",
    name: "soy allergy friendly",
    image: "soyAllergy.svg",
    checked: false,
  },
  "Peanut Allergy Friendly": {
    id: "Peanut Allergy Friendly",
    name: "peanut allergy friendly",
    image: "peanutAllergy.svg",
    checked: false,
  },
  "Gluten Friendly Options": {
    id: "Gluten Friendly Options",
    name: "gluten-free options",
    image: "glutenFriendlyOptions.svg",
    checked: false,
  },
  "100% Gluten Free": {
    id: "100% Gluten Free",
    name: "100% gluten free",
    image: "100GlutenFree.svg",
    checked: false,
  },
  "Dedicated Gluten-Free Food Prep": {
    id: "Dedicated Gluten-Free Food Prep",
    name: "gluten-free prep",
    image: "glutenFreePrep.svg",
    checked: false,
  },
  "Vegan Options": {
    id: "Vegan Options",
    name: "vegan options",
    image: "veganOptions.svg",
    checked: false,
  },
  "100% Vegan": {
    id: "100% Vegan",
    name: "100% vegan",
    image: "100Vegan.svg",
    checked: false,
  },
  "100% Vegetarian": {
    id: "100% Vegetarian",
    name: "100% vegetarian",
    image: "100Vegetarian.svg",
    checked: false,
  },
  Halal: {
    id: "Halal",
    name: "halal",
    image: "halal.svg",
    checked: false,
  },
  Kosher: {
    id: "Kosher",
    name: "kosher",
    image: "kosher.svg",
    checked: false,
  },
  "Low Carb Options": {
    id: "Low Carb Options",
    name: "low carb",
    image: "lowCarb.svg",
    checked: false,
  },
};

export const supportedDiets = {
  Vegan: {
    id: "Vegan",
    image: "vegan.svg",
    checked: false,
  },
  Vegetarian: {
    id: "Vegetarian",
    image: "vegetarian.svg",
    checked: false,
  },
  Paleo: {
    id: "Paleo",
    image: "paleo.svg",
    checked: false,
  },
  Keto: {
    id: "Keto",
    image: "keto.svg",
    checked: false,
  },
  "Gluten Free": {
    id: "Gluten Free",
    image: "glutenFree.svg",
    checked: false,
  },
  Halal: {
    id: "Halal",
    image: "halal.svg",
    checked: false,
  },
  Kosher: {
    id: "Kosher",
    image: "kosher.svg",
    checked: false,
  },
  "High FODMAP": {
    id: "High FODMAP",
    image: "fodmap.svg",
    checked: false,
  },
  Celiac: {
    id: "Celiac",
    image: "celiac.svg",
    checked: false,
  },
};

export const dishAllergens = {
  "High Fodmap Foods": {
    id: "High Fodmap Foods",
    name: "High FODMAP",
    contains: false,
    modifiable: false,
  },
  "High Sugar": {
    id: "High Sugar",
    name: "High Sugar",
    contains: false,
    modifiable: false,
  },
  "High Carbs": {
    id: "High Carbs",
    name: "Non-Ketogenic Ingredients",
    contains: false,
    modifiable: false,
  },
  Beef: {
    id: "Beef",
    name: "Beef",
    contains: false,
    modifiable: false,
  },
  Pork: {
    id: "Pork",
    name: "Pork",
    contains: false,
    modifiable: false,
  },
  "Red Meat": {
    id: "Red Meat",
    name: "Other Red Meat",
    contains: false,
    modifiable: false,
  },
  Fish: {
    id: "Fish",
    name: "Fish",
    contains: false,
    modifiable: false,
  },
  Tofu: {
    id: "Tofu",
    name: "Tofu",
    contains: false,
    modifiable: false,
  },
  "Other Seafood": {
    id: "Other Seafood",
    name: "Other Seafood",
    contains: false,
    modifiable: false,
  },
  "White Meat": {
    id: "White Meat",
    name: "White Meat",
    contains: false,
    modifiable: false,
  },
  Shellfish: {
    id: "Shellfish",
    name: "Shellfish",
    contains: false,
    modifiable: false,
  },
  Rice: {
    id: "Rice",
    name: "Rice",
    contains: false,
    modifiable: false,
  },
  Wheat: {
    id: "Wheat",
    name: "Wheat",
    contains: false,
    modifiable: false,
  },
  Corn: {
    id: "Corn",
    name: "Corn",
    contains: false,
    modifiable: false,
  },
  Gluten: {
    id: "Gluten",
    name: "Gluten",
    contains: false,
    modifiable: false,
  },
  Dairy: {
    id: "Dairy",
    name: "Dairy",
    contains: false,
    modifiable: false,
  },
  Eggs: {
    id: "Eggs",
    name: "Eggs",
    contains: false,
    modifiable: false,
  },
  Honey: {
    id: "Honey",
    name: "Honey",
    contains: false,
    modifiable: false,
  },
  Cheese: {
    id: "Cheese",
    name: "Cheese",
    contains: false,
    modifiable: false,
  },
  Peanuts: {
    id: "Peanuts",
    name: "Peanuts",
    contains: false,
    modifiable: false,
  },
  Soy: {
    id: "Soy",
    name: "Soy",
    contains: false,
    modifiable: false,
  },
  Sesame: {
    id: "Sesame",
    name: "Sesame",
    contains: false,
    modifiable: false,
  },
  Treenuts: {
    id: "Treenuts",
    name: "Treenuts",
    contains: false,
    modifiable: false,
  },
  Legumes: {
    id: "Legumes",
    name: "Legumes",
    contains: false,
    modifiable: false,
  },
  Potatoes: {
    id: "Potatoes",
    name: "Potatoes",
    contains: false,
    modifiable: false,
  },
  Fruit: {
    id: "Fruit",
    name: "Fruit",
    contains: false,
    modifiable: false,
  },
  Garlic: {
    id: "Garlic",
    name: "Garlic",
    contains: false,
    modifiable: false,
  },
  Nightshades: {
    id: "Nightshades",
    name: "Nightshades",
    contains: false,
    modifiable: false,
  },
  Mushrooms: {
    id: "Mushrooms",
    name: "Mushrooms",
    contains: false,
    modifiable: false,
  },
  "Spicy Food": {
    id: "Spicy Food",
    name: "Spicy Food",
    contains: false,
    modifiable: false,
  },
  Onions: {
    id: "Onions",
    name: "Onions",
    contains: false,
    modifiable: false,
  },
};
