import React from "react";
import PropTypes from "prop-types";
import "./DefaultInput.css";

const DefaultInput = ({
  name,
  type,
  placeholder,
  inputValue,
  classNameProp,
  maxLength,
  required,
  onChange,
  disabled = false,
}) => {
  return (
    <input
      className={`defaultInput defaultInputFont ${classNameProp}`}
      placeholder={placeholder}
      onChange={onChange}
      type={type}
      name={name}
      value={inputValue}
      maxLength={maxLength}
      required={required}
      disabled={disabled}
    />
  );
};

DefaultInput.defaultProps = {
  type: "text",
  placeholder: "Enter here",
  value: "",
  required: false,
  maxLength: 255,
};

DefaultInput.propTypes = {
  required: PropTypes.bool,
  onChange: PropTypes.func,
  maxLength: PropTypes.number,
};

export default DefaultInput;
