import { MENU_TYPES } from "../config/constants";

export const stripItems = (dishs) => {
  const arr = new Set();
  dishs.forEach((element) => {
    element.children.forEach((element) => {
      element.children.forEach((element) => {
        arr.add(element);
      });
    });
  });
  return arr;
};

export const buildArrayFromItems = (items) => {
  const menusSet = new Set();
  const sectionsSet = new Set();
  const arr = [];

  const newDishs = Array.from(items).map((dish, index) => {
    if (
      dish.itemMenu == undefined ||
      typeof dish.itemMenu !== "string" ||
      dish.itemMenu === ""
    )
      dish.itemMenu = "menu";

    if (
      dish.itemSection == undefined ||
      typeof dish.itemSection !== "string" ||
      dish.itemSection === ""
    )
      dish.itemSection = "section";

    menusSet.add(dish.itemMenu);
    sectionsSet.add(`${dish.itemMenu}#${dish.itemSection}`);
    const newItem = {
      ...dish,
      id: index + 1,
      type: MENU_TYPES.ITEM,
    };
    return newItem;
  });

  const sectionsWithItems = Array.from(sectionsSet).map((section, index) => {
    const [menu, sectionName] = section.split("#");
    const sectionChildren = newDishs.filter(
      (dish) => dish.itemSection === sectionName && dish.itemMenu === menu
    );
    return {
      id: "section" + index,
      itemName: sectionName,
      parentMenu: menu,
      type: MENU_TYPES.SECTION,
      accepts: [MENU_TYPES.ITEM],
      children: sectionChildren,
    };
  });

  Array.from(menusSet).forEach((menu, index) => {
    arr.push({
      id: "menu" + index,
      itemName: menu,
      type: MENU_TYPES.MENU,
      accepts: [MENU_TYPES.SECTION],
      children: sectionsWithItems.filter(
        (section) => section.parentMenu === menu
      ),
    });
  });

  return arr;
};
