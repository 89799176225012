import React from "react";
import "./ContentBox.css";
import "./VisualCommandCard.css";
import DefaultText from "./DefaultText";

const VisualCommandCard = ({ src, alt, text, classNameProp, onClick }) => {
  return (
    <label
      onClick={onClick}
      className={`contentBoxWrapper blueWrapper visualCommandCard ${classNameProp}`}
    >
      <img
        className="alignCenter visualCommandCardImg"
        src={src}
        alt={alt ? text : alt}
      />
      <DefaultText classNameProp={"s18 w6 whiteText alignCenter"}>
        {text}
      </DefaultText>
    </label>
  );
};

export default VisualCommandCard;
