import React, { useEffect, useRef, useState } from "react";
import ContentBox from "../Components/ContentBox";
import DefaultInput from "../Components/DefaultInput";
import "./BasicLocationInfo.css";
import { useLocation } from "react-router-dom";
import DefaultText from "../Components/DefaultText";
import DefaultButton from "../Components/DefaultButton";
import { useRecoilState, useRecoilValue } from "recoil";
import { locationRouteState, locationsState } from "../States/Atoms";
import Modal from "../Components/Modal";
import DropDownList from "../Components/DropDownList";
import EditButton from "../Components/EditButton";

const BasicLocationInfo = () => {
  const [locationRoute, setLocationRoute] = useRecoilState(locationRouteState);
  const locations = useRecoilValue(locationsState);
  const [showModal, setShowModal] = useState(false);
  const selectedID = useRef(
    locations.length > 0 ? locations[0].restaurantID : ""
  );
  const location = useLocation();

  useEffect(() => {
    if (
      location.pathname.includes("/AddLocation") &&
      locationRoute?.restaurantName === "" &&
      locations.length > 0
    ) {
      setShowModal(true);
    }
  }, [location]);

  const ImageHandler = () => {
    if (locationRoute.restaurantImage) {
      if (locationRoute.restaurantImage === "placeholder") {
        return <img src={"/images/sample.png"} alt="" />;
      }
      return <img src={locationRoute.restaurantImage} alt="" />;
    } else {
      return (
        <label htmlFor="uploadPhotoId" className="uploadPhotoContainer">
          <div className="uploadHere">
            <div className="uploadHere__plus"></div>
            <DefaultText classNameProp={`uploadHere__text`}>
              Click To Upload Photo.
            </DefaultText>
          </div>
        </label>
      );
    }
  };

  const handleAutocomplete = () => {
    const restaurant = locations.find(
      (location) => location.restaurantID == selectedID.current
    );
    setLocationRoute({
      ...locationRoute,
      restaurantName: restaurant.restaurantName,
      restaurantImage: restaurant.restaurantImage,
      restaurantDietaryPolicy: restaurant.restaurantDietaryPolicy,
      restaurantInstagramID: restaurant.restaurantInstagramID,
      website: restaurant.website,
      phoneNumber: restaurant.phoneNumber,
      establishmentPractices: restaurant.establishmentPractices,
      supportedDiets: restaurant.supportedDiets,
      supportedAllergens: restaurant.supportedAllergens,
    });
    setShowModal(false);
  };

  const handleRestaurantNameChange = (e) => {
    setLocationRoute({
      ...locationRoute,
      restaurantName: e.target.value,
    });
  };

  const handleNeigborhoodChange = (e) => {
    setLocationRoute({
      ...locationRoute,
      neighbourhood: e.target.value,
    });
  };

  const handleAddressChange = (e) => {
    setLocationRoute({
      ...locationRoute,
      address: e.target.value,
    });
  };

  const handleImageChange = (e) => {
    setLocationRoute({
      ...locationRoute,
      restaurantImage: URL.createObjectURL(e.target.files[0]),
    });
  };

  const clearImage = () => {
    setLocationRoute({
      ...locationRoute,
      restaurantImage: null,
    });
  };

  return (
    <>
      <div>
        <div className="basicLocationInfo">
          <ContentBox
            title="Restaurant Name"
            desc="Enter your restaurant name below. For example: Starbucks"
          >
            <DefaultInput
              placeholder={`enter restaurant name`}
              required={true}
              inputValue={locationRoute.restaurantName}
              name={``}
              onChange={handleRestaurantNameChange}
            />
          </ContentBox>
          <ContentBox
            title="Restaurant Address"
            desc="Write and search for the address for one of your restaurant locations."
          >
            <DefaultInput
              placeholder={`write your address`}
              required={true}
              inputValue={locationRoute.address}
              name={``}
              onChange={handleAddressChange}
            />
          </ContentBox>
          <ContentBox
            title="Restaurant Photo"
            desc="Upload a picture, preferably a picture of a dish, or the interior of your restaurant to give customers idea of what to expect."
          >
            <ImageHandler />
            <input
              id="uploadPhotoId"
              style={{ zIndex: -100, display: "none" }}
              type={"file"}
              autoComplete={`off`}
              onChange={handleImageChange}
              accept={"image/*"}
            />
            <DefaultButton
              text={`reupload`}
              classNameProp={`reuploadBtn`}
              onClick={clearImage}
            />
          </ContentBox>
          <ContentBox
            title="Location Indicator Name"
            desc={
              <>
                "Enter your location specific indicator below. For example (in
                bold): Starbucks -<strong> Central Park</strong>."
              </>
            }
          >
            <DefaultInput
              placeholder={`enter indicator`}
              required={true}
              inputValue={locationRoute.neighbourhood}
              name={``}
              onChange={handleNeigborhoodChange}
            />
          </ContentBox>
        </div>
      </div>
      <Modal show={showModal} setShowToggle={() => setShowModal(false)}>
        <div className="centerBox">
          <DefaultText classNameProp={`cyanText w5 s18`}>
            Add New Location
          </DefaultText>
          <DefaultText classNameProp={"w4"}>
            If you have similar information to that of a location you have
            already added, we will autofill information except Location
            Indicator, Address, Contact-Info, and Menu. Typography
          </DefaultText>
          <DefaultText>Restaurant Location</DefaultText>
          <DropDownList
            listOfOptions={locations}
            onChange={(e) => {
              const restaurantId = e.target.value;
              selectedID.current = restaurantId;
            }}
          />
          <DefaultButton
            classNameProp={"purple"}
            text={
              <DefaultText classNameProp={"whiteText"}>
                Auto Complete Information
              </DefaultText>
            }
            onClick={handleAutocomplete}
          />
          <DefaultText>Or,</DefaultText>
          <EditButton
            showIcon={false}
            classNameProp={"alignCenter"}
            text="Add completely new location"
            onClick={() => {
              setShowModal(false);
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default BasicLocationInfo;
