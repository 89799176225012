import React from "react";
import "./DefaultButton.css";
import "./DotTyping.css";

const DefaultButton = ({ text, onClick, classNameProp, disabled, loading }) => {
  return (
    <button
      className={`defaultButton ${classNameProp} ${disabled ? "disabled" : ""}`}
      type="button"
      onClick={onClick}
      disabled={disabled || loading}
    >
      <div className={`${loading ? "staging" : ""}`}>
        <div className={`${loading ? "dot-typing" : ""}`}>
          {loading ? "" : text}
        </div>
      </div>
    </button>
  );
};

export default DefaultButton;
