import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./LocationsNavBar.css";

const ActiveSvg = ({ disabled }) => {
  if (disabled) {
    return <></>;
  }
  return (
    <svg
      width="8"
      height="7"
      viewBox="0 0 8 7"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        x1="5.85367"
        y1="1.83316"
        x2="2.34341"
        y2="5.34341"
        stroke="#E8E4E4"
        strokeWidth="4"
      />
      <line
        x1="5.85367"
        y1="1.83316"
        x2="2.34341"
        y2="5.34341"
        stroke="#E8E4E4"
        strokeWidth="4"
      />
      <line
        x1="5.85367"
        y1="1.83316"
        x2="2.34341"
        y2="5.34341"
        stroke="#CEDEF5"
        strokeWidth="4"
      />
    </svg>
  );
};

const LocationsNavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [detailsActive, setDetailsActive] = useState(true);
  const [menuActive, setMenuActive] = useState(false);
  const [postActive, setPostActive] = useState(false);

  useEffect(() => {
    if (location.pathname.includes("/Details")) {
      setDetailsActive(true);
    } else if (location.pathname.includes("/Menu")) {
      setMenuActive(true);
    } else if (
      location.pathname.includes("/Post") ||
      location.pathname.includes("/Slug")
    ) {
      setMenuActive(true);
      setPostActive(true);
    }
  }, [location]);

  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
  }, []);

  const alertUser = (event) => {
    event.preventDefault();
    event.returnValue = "";
  };

  const handleNavigation = (navTo) => {
    switch (navTo) {
      case "Details":
        //navigate(`/AddLocation/${navTo}/Basic`);
        break;
      case "Menu":
        if (menuActive) navigate(`/AddLocation/${navTo}`);
        break;
      case "Post":
        if (postActive) navigate(`/AddLocation/${navTo}`);
        break;
      default:
        return;
    }
  };

  return (
    <div className="locationsNavBar">
      <div className="locationsNavBarContainer">
        <h1 className="locationsHeading">Add Location Details</h1>
        <div className="flexRow marginRN">
          <div
            className="clickableContainer"
            onClick={() => handleNavigation("Details")}
          >
            <div className="circleElement">
              <div className="lineElement clear" />
              <div className={`circleDiv ${detailsActive ? "selected" : ""}`}>
                <ActiveSvg disabled={!location.pathname.includes("/Details")} />
              </div>
              <div className="lineElement" />
            </div>
            <h3 className="navText">Restaurant Details</h3>
          </div>
          <div
            className="clickableContainer"
            onClick={() => handleNavigation("Menu")}
          >
            <div className="circleElement">
              <div className="lineElement" />
              <div className={`circleDiv ${menuActive ? "selected" : ""}`}>
                <ActiveSvg disabled={!location.pathname.includes("/Menu")} />
              </div>
              <div className="lineElement" />
            </div>
            <h3 className="navText">Menu</h3>
          </div>
          <div
            className="clickableContainer"
            onClick={() => handleNavigation("Post")}
          >
            <div className="circleElement">
              <div className="lineElement" />
              <div className={`circleDiv ${postActive ? "selected" : ""}`}>
                <ActiveSvg
                  disabled={
                    !location.pathname.includes("/Post") &&
                    !location.pathname.includes("/Slug")
                  }
                />
              </div>
              <div className="lineElement clear" />
            </div>
            <h3 className="navText">Post Restaurant</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LocationsNavBar;
