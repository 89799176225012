import React, { useState } from "react";
import DefaultButton from "./DefaultButton";
import DefaultText from "./DefaultText";
import "./DietaryInfoCard.css";
import Modal from "./Modal";
import "./Modal.css";

const DietaryInfoCard = ({
  id,
  src,
  title,
  classNameProp,
  checked,
  handleClick = () => {},
  disabled = false,
}) => {
  const [show, setShow] = useState(false);

  const handleShow = () => {
    if (disabled) return;
    if (checked) {
      handleClick(id, false);
    } else {
      setShow(true);
    }
  };

  const handleSave = () => {
    if (disabled) return;
    handleClick(id, true);
    setShow(false);
  };

  const handleClose = () => {
    setShow(false);
  };

  return (
    <>
      <label
        className={`dietaryInfoCard ${classNameProp} ${
          checked ? "activeDietCard" : ""
        }`}
        onClick={handleShow}
      >
        <img
          className={"dietImage"}
          src={`/images/DietaryIcons/${src}`}
          alt={title}
        />
        <p className={`dietText`}>{title}</p>
      </label>
      <Modal show={show} setShowToggle={setShow}>
        <div className="modalImageText">
          <img
            src={`/images/DietaryIcons/${src}`}
            className={"modalImage"}
            alt={title}
          />
          <DefaultText classNameProp={"s18 w6 ttC alignCenter montAlt"}>
            {title}
          </DefaultText>
        </div>
        <DefaultText classNameProp={"s14"}>
          Select this diet tag, if your restaurant has the necessary protocols
          and preparation to comfortably serve a customer with severe food
          allergies.
        </DefaultText>
        <div className="modalButtonsContainer">
          <DefaultButton
            classNameProp={"modalButton"}
            text={"Applies to my restaurant"}
            onClick={handleSave}
          />
          <DefaultButton
            classNameProp={"closeButton"}
            text={"Doesn't apply"}
            onClick={handleClose}
          />
        </div>
      </Modal>
    </>
  );
};

export default DietaryInfoCard;
