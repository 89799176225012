import React from "react";
import DefaultText from "./DefaultText";
import "./LocationBox.css";

const LocationBox = ({
  restaurantName,
  restaurantLocation,
  src,
  verified,
  published,
  active,
  onClick,
}) => {
  if (!active) {
    return (
      <label className="locationBox locationBoxInactive" onClick={onClick}>
        <img src="/images/location.svg" width={25} />
        <DefaultText classNameProp={"s12 w6 blackText"}>
          add location
        </DefaultText>
      </label>
    );
  }
  return (
    <label className="locationBox" onClick={onClick}>
      <img
        className="locationBoxImg"
        src={src === "placeholder" ? "/images/sample.png" : src}
        alt="Image of Restaurant"
      />
      <div className="locationBoxOverlay">
        <div className="locationBoxTextBelow">
          <DefaultText classNameProp={"s12 w6 whiteText"}>
            {restaurantName}
          </DefaultText>
          <DefaultText classNameProp={"s10 w7 whiteText"}>
            {restaurantLocation}
          </DefaultText>
          <DefaultText classNameProp={"s10 whiteText"}>
            {verified ? "Verified" : "Not Verified"}
          </DefaultText>
        </div>
        <div className="locationBoxTextAbove">
          <div className="locationBoxCircle"></div>
          <DefaultText classNameProp={"s10 whiteText"}>
            {published ? "Published" : "Not Published"}
          </DefaultText>
        </div>
      </div>
    </label>
  );
};

export default LocationBox;
