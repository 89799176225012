import React, { useEffect, useState } from "react";
import ContentBox from "../Components/ContentBox";
import DefaultInput from "../Components/DefaultInput";
import DefaultButton from "../Components/DefaultButton";
import "./LocationMenu.css";
import DefaultText from "../Components/DefaultText";
import Modal from "../Components/Modal";
import CardHeaderText from "../Components/CardHeaderText";
import { submitMenuState } from "../States/Atoms";
import { useRecoilState } from "recoil";

const LocationMenu = () => {
  const [menuItems, setMenuItems] = useRecoilState(submitMenuState);
  const [menuLink, setMenuLink] = useState("");
  const [showModal, setShowModal] = useState(true);
  const [menuImages, setMenuImages] = useState([]);
  const [disable, setDisable] = useState(false);

  const handleInputChange = (e) => {
    if (e.target.value.trim?.()?.length > 0) {
      setDisable(false);
    } else {
      setDisable(true);
    }
    setMenuLink(e.target.value);
  };

  const handleSubmitMenuLink = () => {
    setMenuItems([...menuItems, menuLink]);
    setMenuLink("");
    setDisable(true);
  };

  const handleImageChange = (e) => {
    const files = e.target.files;
    const filesArr = Array.from(files);
    setMenuImages(filesArr);
  };

  useEffect(() => {
    if (menuImages.length > 0) {
      const items = [];
      for (let i = 0; i < menuImages.length; i++) {
        const item = menuImages[i];
        if (!menuItems.includes(item)) {
          items.push(item);
        }
      }
      setMenuItems([...menuItems, ...items]);
      setMenuImages([]);
    }
  }, [menuImages]);

  return (
    <>
      <div className="locationMenuUpload">
        <div className="menuUpload">
          <ContentBox
            classNameProp={"contentBoxSecondary"}
            title="Upload Menu"
            desc="Upload menu files here. It can be a PDF, a JPG, or even excel spreadsheet, or word document. You can upload more than one file."
          >
            <label htmlFor="uploadPhotoId" className="fileUpload">
              <p className="fileUploadPlaceholder">Click to add files</p>
              <button className="fileUploadButton">upload</button>
            </label>
          </ContentBox>
          <input
            id="uploadPhotoId"
            style={{ zIndex: -100, display: "none" }}
            type={"file"}
            multiple={true}
            onChange={handleImageChange}
            accept={"images/*,.pdf,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png"}
          />
          <DefaultText classNameProp={"w6 s18 alignCenter"}>or,</DefaultText>
          <div className="menuLink">
            <ContentBox
              classNameProp={"contentBoxSecondary"}
              title="Paste Menu Link"
              desc="Paste menu link here. You can add more than one link, one at a time."
            >
              <DefaultInput
                placeholder={`paste your menu link here`}
                required={false}
                maxLength={255}
                inputValue={menuLink}
                onChange={handleInputChange}
              ></DefaultInput>
              <DefaultButton
                text="add link"
                classNameProp={"purple alignCenter width180 fontSize12"}
                disabled={disable}
                onClick={handleSubmitMenuLink}
              />
            </ContentBox>
          </div>
        </div>

        <ContentBox
          classNameProp={"contentBoxSecondary"}
          title="Uploaded Menus/links"
          desc="Add or upload menus and links for them to appear here."
        >
          {menuItems.map((item, index) => {
            let name = item;
            if (item instanceof File) name = item?.name;
            return (
              <div key={index} className="menuItem">
                <div className="menuText">
                  <h3 className="menuNumber">menu {index + 1}</h3>
                  <h3 className="menuUploadFileName">{name}</h3>
                </div>
                <button
                  className="menuDelete"
                  onClick={() => {
                    setMenuItems(menuItems.filter((_, i) => i !== index));
                  }}
                >
                  <img src="/images/delete.svg" alt="delete icon" />
                </button>
              </div>
            );
          })}
          <p className="uploadMoreText">you can upload more than one menu</p>
        </ContentBox>
      </div>
      <Modal show={showModal} setShowToggle={setShowModal}>
        <CardHeaderText classNameProp={`alignCenter`}>
          Time to Add Menu
        </CardHeaderText>
        <DefaultText classNameProp={`s14`}>
          Time to get to the main part, upload your menu files and we will take
          care of individually adding menu items for you to verify and edit.
        </DefaultText>
        <DefaultButton
          text="Add Menus"
          classNameProp={"purple alignCenter width240 fontSize12 mont"}
          disabled={disable}
          onClick={() => setShowModal(false)}
        />
      </Modal>
    </>
  );
};

export default LocationMenu;
