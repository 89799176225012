import React from "react";
import PropTypes from "prop-types";
import "./DietToken.css";

const DietToken = ({ title, src, classNameProp, handleClick }) => {
  return (
    <label className={`dietToken ${classNameProp}`} onClick={handleClick}>
      <div className={`dietTokenCircle`}>
        <img className="dietTokenIcon" src={src} alt={title} />
      </div>
      <div>
        <p className="dietTokenText">{title}</p>
      </div>
    </label>
  );
};

DietToken.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
  circleColor: PropTypes.string,
};

export default DietToken;
