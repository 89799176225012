import React from "react";
import "./ReminderBox.css";
import DefaultText from "./DefaultText";
import DefaultTitle from "./DefaultTitle";
import "./ContentBox.css";

const ReminderBox = ({ src, alt, title, desc }) => {
  return (
    <div className="contentBoxWrapper rWrapper">
      <img className="remindersImg" src={src} alt={alt}></img>
      <div className="remindersText">
        <DefaultTitle type={4} classNameProp={"s18 w7 grayText"}>
          {title}
        </DefaultTitle>
        <DefaultText classNameProp={"s14 grayText"}>{desc}</DefaultText>
      </div>
    </div>
  );
};

export default ReminderBox;
