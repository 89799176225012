import React from "react";
import "./DietaryInfoContentBox.css";

const DietaryInfoContentBox = ({ title, children }) => {
  return (
    <div className="dietItem">
      <h3 className="dietTitle">{title}</h3>
      <div className="dietInfoCards">{children}</div>
    </div>
  );
};

export default DietaryInfoContentBox;
