import React, { useState } from "react";
import PropTypes from "prop-types";
import "./DefaultText.css";

const DefaultTitle = ({ type, children, classNameProp }) => {
  switch (type) {
    case 1:
      return <h1 className={`defaultText ${classNameProp}`}>{children}</h1>;
    case 2:
      return <h2 className={`defaultText ${classNameProp}`}>{children}</h2>;
    case 3:
      return <h3 className={`defaultText ${classNameProp}`}>{children}</h3>;
    case 4:
      return <h4 className={`defaultText ${classNameProp}`}>{children}</h4>;
    case 5:
      return <h5 className={`defaultText ${classNameProp}`}>{children}</h5>;
    case 6:
      return <h6 className={`defaultText ${classNameProp}`}>{children}</h6>;
    default:
      return <h1 className={`defaultText ${classNameProp}`}>{children}</h1>;
  }
};

DefaultTitle.propTypes = {
  type: PropTypes.number,
};

export default DefaultTitle;
