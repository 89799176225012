import React from "react";
import "./EditButton.css";
import "./DefaultButton.css";
import DefaultText from "./DefaultText";

const EditButton = ({ text, onClick, classNameProp, showIcon = true }) => {
  return (
    <button
      className={`editButton ${classNameProp}`}
      type="button"
      onClick={onClick}
    >
      {showIcon && <img src="/images/edit.svg" alt="Edit icon" />}
      <DefaultText>{text}</DefaultText>
    </button>
  );
};

export default EditButton;
