import React, { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import DefaultInput from "../Components/DefaultInput";
import DefaultText from "../Components/DefaultText";
import DefaultTitle from "../Components/DefaultTitle";
import { locationRouteState } from "../States/Atoms";
import slugify from "slugify";
import "./SlugPage.css";
import { checkIsUniqueSlug } from "../Managers";
import useAuth from "../States/Auth/AuthHooks";
import DefaultButton from "../Components/DefaultButton";

const SLUG_CHECK_TIMEOUT = 1000;

const SlugPage = (props) => {
  const [location, setLocation] = useRecoilState(locationRouteState);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  let [slug, setSlug] = useState(() => {
    if (location.restaurantName != "")
      return slugify(`${location.restaurantName}-${location.neighbourhood}`);
    else return "";
  });

  const auth = useAuth();

  const handleSlugChange = (e) => {
    setSuccess(false);
    setSlug(slugify(e.target.value, { trim: false, lower: true }));
  };

  const checkSlug = async (slugToCheck) => {
    setLoading(true);
    setSuccess(false);
    if (slugToCheck.length === 0) {
      setLoading(false);
      return;
    }
    const isUnique = await checkIsUniqueSlug(auth.getToken(), slugToCheck);
    if (isUnique) {
      setSuccess(true);
      slug = slugToCheck; // avoid render
    } else {
      setSuccess(false);
    }
    setLoading(false);
  };

  const setLink = (slug) => {
    if (success) {
      setLocation((prev) => ({
        ...prev,
        slugName: slug,
      }));
    }
  };

  useEffect(() => {
    let checkTimer = setTimeout(() => {
      checkSlug(slugify(slug));
    }, SLUG_CHECK_TIMEOUT);
    return () => {
      clearTimeout(checkTimer);
    };
  }, [slug]);

  const Status = () => {
    if (loading) {
      return (
        <DefaultText classNameProp={`w5 grayText`}>Loading...</DefaultText>
      );
    } else if (success) {
      return (
        <DefaultText classNameProp={`w5 greenText`}>
          link name available!
        </DefaultText>
      );
    } else {
      return (
        <DefaultText classNameProp={`w5 redText`}>
          link name not available
        </DefaultText>
      );
    }
  };

  return (
    <div className="slugPage">
      <div className="slugWrapper">
        <DefaultTitle type={6} classNameProp="w7 s18 pad0">
          Set Your Menu Link:
        </DefaultTitle>
        <DefaultText classNameProp="w5 s12">
          Your restaurant location's menu will be published to a web link.
        </DefaultText>
        <DefaultText classNameProp="w7">
          What do you want to name the link for this location?
        </DefaultText>
        <div className="slugLink">
          <DefaultText classNameProp="w6 s16 row">
            app.honeycomb.ai/
          </DefaultText>
          <DefaultInput
            type={"text"}
            placeholder="name-location"
            inputValue={slug}
            onChange={handleSlugChange}
            disabled={loading}
            maxLength={50}
          />
        </div>
        <div className="slugRightAlign">
          <Status />
        </div>
        <DefaultButton
          text="Set Link"
          classNameProp={"purple alignCenter width240 fontSize14 mont"}
          onClick={() => {
            setLink(slug);
          }}
          disabled={!success}
        />
        <div className=""></div>
      </div>
    </div>
  );
};

export default SlugPage;
