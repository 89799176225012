import React from "react";
import { useRecoilState } from "recoil";
import ContentBox from "../Components/ContentBox";
import DefaultInput from "../Components/DefaultInput";
import DefaultText from "../Components/DefaultText";
import DefaultTextArea from "../Components/DefaultTextArea";
import { locationRouteState } from "../States/Atoms";
import "./LocationPolicy.css";

const LocationPolicy = () => {
  const [locationRoute, setLocationRoute] = useRecoilState(locationRouteState);

  const handlePolicyChange = (e) => {
    setLocationRoute({
      ...locationRoute,
      restaurantDietaryPolicy: e.target.value,
    });
  };

  return (
    <div className="locationPolicy">
      <ContentBox
        title="Dietary Policy"
        desc="If your restaurant follows any specific practises to ensure dietary safety you can write it here for your customers to see. Follow given example to understand what a dietary policy looks like.
        (MAX characters 1000)
        "
      >
        <DefaultTextArea
          classNameProp={`textareaHeight`}
          placeholder={"Enter your dietary policy below"}
          inputValue={locationRoute?.restaurantDietaryPolicy}
          onChange={handlePolicyChange}
          required={true}
        />
      </ContentBox>
      <ContentBox
        classNameProp={`contentBoxWidth480`}
        title="Example Dietary Policy"
        desc="Consuming raw or undercooked food like meat, poultry or eggs may increase the risk of foodborne illness. Prior to placing the order, make sure to inform the waiter of any food allergies you might have. Our items are prepared in a common kitchen where traces of wheat, milk, eggs, fish/shellfish, nuts, soy, sesame, mustard, celery & sulfite are present. We cannot guarantee any item is safe for a customer with food allergy. We can verify the raw ingredients we add to our recipes but we cannot verify neither ingredients found in manufactured products, nor prevent any cross contamination resulting from the environment.
        "
      ></ContentBox>
    </div>
  );
};

export default LocationPolicy;
