import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil";
import { deepClone } from "../../config/constants";
import { postRestaurant, postSlug } from "../../Managers";
import {
  locationRouteState,
  locationsStateSelectorFamily,
  submitMenuState,
} from "../../States/Atoms";
import useAuth from "../../States/Auth/AuthHooks";
import DefaultButton from "../DefaultButton";
import DefaultText from "../DefaultText";
import "./LocationsSubBar.css";

const LocationsSubBar = ({ editFlow = false }) => {
  const [locationRoute, setLocationRoute] = useRecoilState(locationRouteState);
  const auth = useAuth();
  const [menuLinks, setMenuLinks] = useRecoilState(submitMenuState);
  const resetLocationRoute = useResetRecoilState(locationRouteState);
  const resetMenuLinks = useResetRecoilState(submitMenuState);
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buttonName, setButtonName] = useState("next");
  const restaurant = useRecoilValue(
    locationsStateSelectorFamily(params?.index)
  );

  const locationChecks = () => {
    if (editFlow && !restaurant) navigate("/Home");
    if (editFlow) {
      setButtonName("save changes");
      if (location.pathname.includes("/Details")) {
        if (
          locationRoute.restaurantName !== restaurant.restaurantName ||
          locationRoute.address !== restaurant.address ||
          locationRoute.neighbourhood !== restaurant.neighbourhood ||
          locationRoute.restaurantDietaryPolicy !==
            restaurant.restaurantDietaryPolicy ||
          locationRoute.phoneNumber !== restaurant.phoneNumber ||
          locationRoute.restaurantInstagramID !==
            restaurant.restaurantInstagramID ||
          locationRoute.website !== restaurant.website ||
          locationRoute.establishmentPractices !==
            restaurant.establishmentPractices
        )
          setDisabled(false);
        else setDisabled(true);
      }
    } else {
      if (
        !location.pathname.includes("/AddLocation/Details/Basic") &&
        location.pathname.includes("/AddLocation/Details/") &&
        locationRoute.restaurantName === ""
      ) {
        navigate(`/AddLocation/Details/Basic`);
      }
      if (location.pathname.includes("/Details")) {
        setButtonName("next");
        if (location.pathname.includes("Details/Basic")) {
          if (
            locationRoute.restaurantName === "" ||
            locationRoute.address === "" ||
            locationRoute.neighbourhood === ""
          )
            setDisabled(true);
          else setDisabled(false);
        } else if (location.pathname.includes("Details/Info")) {
          setDisabled(false);
        } else if (location.pathname.includes("Details/Policy")) {
          setDisabled(false);
        } else if (location.pathname.includes("Details/Contact")) {
          if (locationRoute.phoneNumber === "") setDisabled(true);
          else setDisabled(false);
        }
      } else if (location.pathname.includes("/Menu")) {
        setButtonName("submit menu");
        setDisabled(false);
      } else if (location.pathname.includes("/Slug")) {
        setButtonName("next");
        if (locationRoute.slugName === "") setDisabled(true);
        else setDisabled(false);
      } else if (location.pathname.includes("/Post")) {
        setButtonName("complete posting");
        setDisabled(false);
      }
    }
  };

  useEffect(() => {
    locationChecks();
  }, [locationRoute, location]);

  const handleClick = async () => {
    if (editFlow) {
      setLoading(true);
      await submitEditLocationChanges();
      setLoading(false);
    } else {
      if (location.pathname.includes("/Details")) {
        if (location.pathname.includes("/Basic")) {
          navigate(`/AddLocation/Details/Info`);
        } else if (location.pathname.includes("/Info")) {
          navigate(`/AddLocation/Details/Policy`);
        } else if (location.pathname.includes("/Policy")) {
          navigate(`/AddLocation/Details/Contact`);
        } else if (location.pathname.includes("/Contact")) {
          await handlePostRestaurant(auth.getToken(), locationRoute);
        }
      } else if (location.pathname.includes("/Menu")) {
        handleSubmitMenu();
      } else if (location.pathname.includes("/Slug")) {
        handleSubmitSlug();
      } else if (location.pathname.includes("/Post")) {
        auth.completeOnboarding();
        navigate(`/Home`);
      }
    }
  };

  const handleSubmitSlug = async () => {
    setLoading(true);
    setDisabled(true);
    const response = await postSlug(
      auth.getToken(),
      locationRoute.restaurantID,
      locationRoute.slugName
    );
    if (response) {
      setLoading(false);
      setDisabled(false);
      navigate("/AddLocation/Post", { replace: true });
    } else {
      setLoading(false);
      setDisabled(false);
    }
  };

  const submitEditLocationChanges = async () => {
    setLoading(true);
    setDisabled(true);
    // TODO: Implement submitChanges
    //
    navigate("/Home");
    resetLocationRoute();
  };

  const handleSubmitMenu = () => {
    setLoading(true);
    //Post menu to server

    navigate(`/AddLocation/Slug`);
    setLoading(false);
  };

  const handleCancel = () => {
    resetLocationRoute();
    if (params.index != null) {
      navigate(`/Home/${params.index}`, { replace: true });
    } else {
      navigate(`/Home`);
    }
  };

  const handlePostRestaurant = async (token, restaurant) => {
    setLoading(true);
    setDisabled(true);
    const restaurantCopy = deepClone(restaurant);
    delete restaurantCopy?.restaurantID;
    delete restaurantCopy?.isVerified;
    const response = await postRestaurant(token, restaurantCopy);
    if (response !== null) {
      setLocationRoute((prev) => ({
        ...prev,
        restaurantID: response,
      }));
      // Successful post
      setLoading(false);
      setDisabled(false);
      navigate(`/AddLocation/Menu`);
    } else {
      // Fails
      setLoading(false);
      setDisabled(false);
      // navigate(`/AddLocation/Menu`);
    }
  };

  const HandleNavBar = () => {
    if (auth.user?.inOnboarding) {
      return (
        <>
          <div className="subBarRestaurant">
            <DefaultText classNameProp={"s18 w6"}>Restaurant:</DefaultText>
            <DefaultText classNameProp={"s18 locationsSubBarText"}>
              {locationRoute?.restaurantName}
            </DefaultText>
          </div>
          <DefaultButton
            classNameProp={"cyan width180"}
            text={buttonName}
            disabled={disabled}
            onClick={handleClick}
          />
        </>
      );
    } else {
      return (
        <>
          <div className="subBarRestaurant">
            <DefaultText classNameProp={"s18 w6"}>Restaurant:</DefaultText>
            <DefaultText classNameProp={"s18 locationsSubBarText"}>
              {locationRoute?.restaurantName}
            </DefaultText>
          </div>
          <div className="buttonsContainer">
            <DefaultButton
              text={"cancel"}
              classNameProp="skeleton cancelButton"
              onClick={handleCancel}
            />
            <DefaultButton
              classNameProp={"cyan paddingH45"}
              text={buttonName}
              disabled={disabled}
              loading={loading}
              onClick={handleClick}
            />
          </div>
        </>
      );
    }
  };

  return (
    <div className="locationsSubBar">
      <div className="locationsSubBarContainer">
        <HandleNavBar />
      </div>
    </div>
  );
};

export default LocationsSubBar;
