import React from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import DefaultText from "../Components/DefaultText";
import DefaultTitle from "../Components/DefaultTitle";
import LocationBox from "../Components/LocationBox";
import { locationsState } from "../States/Atoms";
import "./RestaurantLocations.css";

const RestaurantLocations = () => {
  const navigate = useNavigate();
  const locations = useRecoilValue(locationsState);

  const navigateToRestaurant = (index) => {
    navigate(`/Home/${index}`);
  };
  return (
    <div className="restaurantLocations">
      <div className="restaurantLocationsContainer">
        <div>
          <DefaultTitle type={2} classNameProp={"s18 w6 grayText"}>
            Select A Location
          </DefaultTitle>
          <DefaultText classNameProp={"s14 w5"}>
            Select a location below to edit location specific details.
          </DefaultText>
        </div>
        <div className="rlocationsContainer mt20">
          {locations.map((restaurant, index) => {
            return (
              <LocationBox
                key={index}
                src={restaurant.restaurantImage}
                restaurantName={restaurant.restaurantName}
                restaurantLocation={restaurant.address}
                verified={restaurant.isVerified}
                published={restaurant.isVerified}
                active={true}
                onClick={() => navigateToRestaurant(index)}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RestaurantLocations;
