import React from "react";
import "./ContactBox.css";
import DefaultText from "./DefaultText";

const ContactBox = ({ src, title, children, desc, alt }) => {
  return (
    <div className="contactBoxWrapper">
      <img className="contactBoxImage" src={src} alt={alt}></img>
      <div className="contactBoxContent">
        <h3 className="contactBoxTitle">{title}</h3>
        {children}
        <DefaultText>{desc}</DefaultText>
      </div>
    </div>
  );
};

export default ContactBox;
