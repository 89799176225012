import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AlertComponent from "../Components/AlertComponent";
import DefaultButton from "../Components/DefaultButton";
import DefaultInput from "../Components/DefaultInput";
import useAuth from "../States/Auth/AuthHooks";
import "./Registration.css";

const Login = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const auth = useAuth();
  const [message, setMessage] = useState(null);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSignIn = async () => {
    setMessage(null);
    setLoading(true);
    const locations = await auth.signIn(email, password);
    if (!locations) {
      setMessage("Log in Failed");
      setLoading(false);
      return;
    }
    setLoading(false);
    if (locations.length > 0) {
      navigate("/Home");
    } else {
      navigate("/GetStarted");
    }
  };

  const handleNavigation = () => {
    navigate("/Signup");
  };

  return (
    <div className="gettingIn">
      {message != null && <AlertComponent type="error" text={message} />}
      <div className="wrapper">
        <img
          className="logo"
          src="https://honeycomb.ai/images/Logo-Color.svg"
          alt="Logo of Honeycomb"
        />
        <p className="text">Sign in to your account</p>
        <DefaultInput
          placeholder="email"
          type="email"
          inputValue={email}
          maxLength={255}
          required={true}
          onChange={(event) => setEmail(event.target.value)}
        />
        <DefaultInput
          placeholder="password"
          type="password"
          inputValue={password}
          maxLength={255}
          required={true}
          onChange={(event) => setPassword(event.target.value)}
        />
        <DefaultButton
          onClick={() => handleSignIn()}
          text={"Log In"}
          classNameProp={"cyan"}
          loading={loading}
        />
        <DefaultButton
          onClick={() => navigate("/ForgotPassword")}
          text={"forgot your password"}
          classNameProp={"skeleton"}
        />
      </div>
      <div className="wrapper">
        <p className="text">
          Haven't registered an <br /> account yet?
        </p>
        <DefaultButton
          onClick={() => handleNavigation()}
          text={"Register"}
          classNameProp={"cyan"}
        />
      </div>
    </div>
  );
};

export default Login;
