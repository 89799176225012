import { atom, selector, selectorFamily } from "recoil";
import { deepClone, MENU_TYPES } from "../config/constants";
import { buildArrayFromItems, stripItems } from "../Util/DishItemHelpers";

export const locationRouteState = atom({
  key: "locationRoute",
  default: {
    restaurantID: "",
    restaurantName: "",
    address: "",
    website: "",
    neighbourhood: "",
    establishmentPractices: [],
    supportedDiets: [],
    restaurantImage: null,
    restaurantInstagramID: "",
    isVerified: false,
    phoneNumber: "",
    restaurantDietaryPolicy: "",
    supportedAllergens: [],
    slugName: "",
  },
});

export const locationsState = atom({
  key: "locationsState",
  default: [],
});

export const locationsStateSelectorFamily = selectorFamily({
  key: "locationsStateSelectorFamily",
  get:
    (index) =>
    ({ get }) => {
      const locations = get(locationsState);
      if (index && locations[index]) return locations[index];
      return locations[0] || {};
    },
});

export const submitMenuState = atom({
  key: "submitMenuState",
  default: [],
});

export const sortableFieldState = atom({
  key: "sortableFieldState",
  default: "",
});

export const SelectedSectionIndex = atom({
  key: "SelectedSectionIndex",
  default: [0, 0],
});

const NestedDishsState = atom({
  key: "NestedDishsState",
  default: [],
});

export const rawMenuRequestState = atom({
  key: "rawMenuRequestState",
  default: null,
});

export const dishesChangedState = atom({
  key: "dishesChangedState",
  default: false,
});

export const SelectedItemsStateSelector = selector({
  key: "SelectedItemsStateSelector",
  get: ({ get }) => {
    const [selectedMenu, selectedSection] = get(SelectedSectionIndex);
    const dishs = get(NestedDishsState);
    if (dishs.length > 0) {
      return dishs?.[selectedMenu]?.children?.[selectedSection]?.children;
    }
    return [];
  },
  set: ({ get, set }, selectedItems) => {
    const [selectedMenu, selectedSection] = get(SelectedSectionIndex);
    const dishs = get(NestedDishsState);
    if (dishs.length > 0) {
      const newDishes = deepClone(dishs);
      newDishes[selectedMenu].children[selectedSection].children =
        selectedItems;
      set(NestedDishsState, newDishes);
      set(dishesChangedState, true);
    }
  },
});

export const sortedDishsSelector = selector({
  key: "sortedDishsSelector",
  get: ({ get }) => {
    const sortableField = get(sortableFieldState);
    const dishs = get(NestedDishsState);
    if (sortableField === "") {
      return dishs;
    }
    const rawItems = stripItems(dishs);
    const filteredItems = Array.from(rawItems).filter(
      (item) =>
        item?.itemSection
          ?.toLowerCase()
          ?.includes(sortableField?.toLowerCase()) ||
        item?.itemMenu?.toLowerCase()?.includes(sortableField?.toLowerCase()) ||
        item?.itemDescription
          ?.toLowerCase()
          ?.includes(sortableField?.toLowerCase()) ||
        item?.itemName?.toLowerCase()?.includes(sortableField?.toLowerCase())
    );
    const arr = buildArrayFromItems(filteredItems);
    return arr;
  },
  set: ({ set }, dishs) => {
    set(NestedDishsState, dishs);
  },
});

export const menuServerState = atom({
  key: "menuServerState",
  default: [],
});

export const MenuSubtextSelector = selector({
  key: "MenuSubtextSelector",
  get: ({ get }) => {
    const menu = get(rawMenuRequestState);
    if (!menu) return { menuSubText: "", sectionSubText: "" };
    const selectedItems = get(SelectedItemsStateSelector);
    const subHeadings = menu?.subheadings || [];
    if (selectedItems.length > 0 && subHeadings.length > 0) {
      const selectedItem = selectedItems[0];
      const menuSubheading = subHeadings.find(
        (subHeading) =>
          subHeading?.type === MENU_TYPES.MENU &&
          subHeading?.subheadingTitle === selectedItem?.itemMenu
      );
      const sectionSubHeading = subHeadings.find(
        (subHeading) =>
          subHeading?.type === MENU_TYPES.SECTION &&
          subHeading?.subheadingTitle === selectedItem?.itemSection
      );
      return {
        menuSubText: menuSubheading ? menuSubheading?.subheadingText : "",
        sectionSubText: sectionSubHeading
          ? sectionSubHeading?.subheadingText
          : "",
      };
    }
    return { menuSubText: "", sectionSubText: "" };
  },
});
