import React from "react";
import { useRecoilState } from "recoil";
import ContactBox from "../Components/ContactBox";
import DefaultInput from "../Components/DefaultInput";
import { locationRouteState } from "../States/Atoms";
import "./LocationContact.css";

const LocationContact = () => {
  const [locationRoute, setLocationRoute] = useRecoilState(locationRouteState);

  const handlePhoneNumberChange = (event) => {
    setLocationRoute({
      ...locationRoute,
      phoneNumber: event.target.value,
    });
  };

  const handleWebsiteChange = (event) => {
    setLocationRoute({
      ...locationRoute,
      website: event.target.value,
    });
  };

  const handleSocialChange = (event) => {
    setLocationRoute({
      ...locationRoute,
      restaurantInstagramID: event.target.value,
    });
  };

  return (
    <div className="locationContact">
      <h2 className="locationContactTitle">Contact Details</h2>
      <ContactBox
        title="Phone Number"
        alt="Phone Icon"
        desc="Phone Numbers help the customers confirm the dietary information, or ask further questions if needed."
        src="/images/phone.svg"
      >
        <DefaultInput
          type="tel"
          name="phoneNumber"
          required={true}
          placeholder="eg. +1(778)-227-4813"
          classNameProp={`contactBoxInput`}
          onChange={handlePhoneNumberChange}
          inputValue={locationRoute.phoneNumber}
        />
      </ContactBox>
      <ContactBox
        title="Website (Optional)"
        alt="Web Icon"
        src="/images/www.svg"
      >
        <DefaultInput
          type="url"
          name="website"
          required={false}
          placeholder="eg: www.honeycomb.ai"
          classNameProp={`contactBoxInput`}
          onChange={handleWebsiteChange}
          inputValue={locationRoute.website}
        />
      </ContactBox>
      <ContactBox
        title="Instagram (optional)"
        alt="Instagram Icon"
        src="/images/insta.svg"
      >
        <DefaultInput
          type="text"
          name="socails"
          required={true}
          placeholder="eg: @honeycomb.app"
          classNameProp={`contactBoxInput`}
          onChange={handleSocialChange}
          inputValue={locationRoute.restaurantInstagramID}
        />
      </ContactBox>
    </div>
  );
};

export default LocationContact;
