import React from "react";
import useAuth from "../../States/Auth/AuthHooks";
import { useLocation, useNavigate } from "react-router-dom";
import { NavLink, Outlet } from "react-router-dom";
import "./HomeNavBar.css";

const HomeNavBar = () => {
  const auth = useAuth();
  const navigate = useNavigate();

  const navigateToAccount = () => {
    navigate("/Home/Account");
  };

  const navigateToHome = () => {
    navigate("/Home", { replace: true });
  };

  return (
    <div className="homeNavBar">
      <nav className="homeNavBarContainer">
        <div className="cursorPointer" onClick={navigateToHome}>
          <img src="/images/logo.svg" alt="Honeycomb Logo" />
        </div>
        <ul className="homeNavLinks">
          <li>
            <div>
              <p className="homeText">{auth.user?.email}</p>
              <p className="homeSubText">Account plan</p>
            </div>
          </li>
          <div className="verticalLine"></div>
          <li>
            <button className="homeSingleLink" onClick={navigateToAccount}>
              <img src="/images/account.svg" alt="Account Icon" />
              <p className="homeSubText">Account</p>
            </button>
          </li>
          <li>
            <button className="homeSingleLink" onClick={auth.signOut}>
              <img src="/images/logout.svg" alt="Logout Icon" />
              <p className="homeSubText">Logout</p>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default HomeNavBar;
