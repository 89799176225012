import React from "react";
import "./DropDownList.css";

const DropDownList = ({ listOfOptions, onChange }) => {
  return (
    <select onChange={onChange} className={"dropDownList"}>
      {listOfOptions.map((option, index) => {
        const restaurantName = option.restaurantName;
        const neighbourhood = option.neighbourhood;
        const restaurantID = option.restaurantID;
        return (
          <option key={index} value={restaurantID}>
            {`${restaurantName}${
              neighbourhood !== "" ? ` - ${neighbourhood}` : ""
            }`}
          </option>
        );
      })}
    </select>
  );
};

export default DropDownList;
