import React, { forwardRef, useEffect, useState } from "react";
import DefaultText from "./DefaultText";
import DefaultTitle from "./DefaultTitle";
import EditButton from "./EditButton";
import "./MenuItemCard.css";
import "./ContentBox.css";
import Modal from "./Modal";
import { dishAllergens } from "../Assets/StaticData";
import AllergenToken from "./AllergenToken";
import DefaultButton from "./DefaultButton";
import { useRecoilState } from "recoil";
import { SelectedItemsStateSelector } from "../States/Atoms";
import { deepClone } from "../config/constants";

// default menu item card props

const MenuItemCard = forwardRef(
  (
    {
      item,
      name,
      price,
      desc,
      allergensContained,
      allergensModifiable,
      verified,
      itemID,
    },
    ref
  ) => {
    const [show, setShow] = useState(false);
    const [allergens, setAllergens] = useState({});
    const [sectionItems, setSectionItems] = useRecoilState(
      SelectedItemsStateSelector
    );
    const [changedItems, setChangedItems] = useState([]);
    let headingNumber = 4;

    useEffect(() => {
      if (show) {
        const allergensCopy = deepClone(dishAllergens);
        Object.keys(allergensCopy).forEach((allergen) => {
          const allergenItem = allergensCopy[allergen];
          if (item?.itemContainsVector.includes(allergen)) {
            allergenItem.contains = true;
          }
          if (item?.itemModifiableVector.includes(allergen)) {
            allergenItem.modifiable = true;
            allergenItem.contains = true;
          }
        });
        setAllergens(allergensCopy);
      }
    }, [show]);

    useEffect(() => {
      setChangedItems(deepClone(sectionItems));
    }, [sectionItems]);

    const handleClick = () => {
      setShow(!show);
    };

    const handleSave = () => {
      setSectionItems(changedItems);
      handleClick();
    };

    const handleVerify = () => {
      const newItems = changedItems.map((obj) => {
        if (obj.itemID === itemID) {
          return { ...obj, itemVerified: !obj.itemVerified };
        }
        return obj;
      });
      setSectionItems(newItems);
      setShow(false);
    };

    const toggleAllergen = (allergenID) => {
      const itemToChange = allergens[allergenID];
      if (itemToChange.contains === true && itemToChange.modifiable === false) {
        itemToChange.contains = true;
        itemToChange.modifiable = true;
        // add allergen to itemContainsVector and itemModifiableVector
        setChangedItems((prevItems) => {
          const newItems = prevItems.map((obj) => {
            if (obj.itemID === itemID) {
              return {
                ...obj,
                itemContainsVector: !obj.itemContainsVector.includes(allergenID)
                  ? [...obj.itemContainsVector, allergenID]
                  : obj.itemContainsVector,
                itemModifiableVector: [...obj.itemModifiableVector, allergenID],
              };
            }
            return obj;
          });
          return newItems;
        });
      } else if (
        (itemToChange.modifiable === true && itemToChange.contains === true) ||
        itemToChange.modifiable === true
      ) {
        itemToChange.contains = false;
        itemToChange.modifiable = false;
        // remove allergen from itemContainsVector and itemModifiableVector
        setChangedItems((prevItems) => {
          const newItems = prevItems.map((obj) => {
            if (obj.itemID === itemID) {
              return {
                ...obj,
                itemContainsVector: obj.itemContainsVector.filter(
                  (itemDish) => itemDish !== allergenID
                ),
                itemModifiableVector: obj.itemModifiableVector.filter(
                  (itemDish) => itemDish !== allergenID
                ),
              };
            }
            return obj;
          });
          return newItems;
        });
      } else {
        itemToChange.contains = true;
        itemToChange.modifiable = false;
        // add allergen to itemContainsVector and remove from itemModifiableVector
        setChangedItems((prevItems) => {
          const newItems = prevItems.map((obj) => {
            if (obj.itemID === itemID) {
              return {
                ...obj,
                itemContainsVector: [...obj.itemContainsVector, allergenID],
                itemModifiableVector: obj.itemModifiableVector.filter(
                  (itemDish) => itemDish !== allergenID
                ),
              };
            }
            return obj;
          });
          return newItems;
        });
      }
      setAllergens({ ...allergens, [allergenID]: itemToChange });
    };
    return (
      <>
        <div className="flexRow gap25">
          <div className="contentBoxWrapper menuCard">
            <div ref={ref} className="menuCardTop">
              <div className="flexRow justifyBetween">
                <DefaultTitle
                  type={headingNumber}
                  classNameProp={"s14 w6 blackText"}
                >
                  {name}
                </DefaultTitle>
                <DefaultText classNameProp={"s14 w7 grayText alignRight"}>
                  {price}
                </DefaultText>
              </div>
              <DefaultText classNameProp={"s12"}>{desc}</DefaultText>
            </div>
            <div className="menuCardBelow">
              {item.itemContainsVector?.length !== 0 && (
                <div>
                  <DefaultTitle
                    type={headingNumber + 1}
                    classNameProp={"s12 w7 grayText"}
                  >
                    Allergens Contained
                  </DefaultTitle>
                  <div className="menuAllergenTokenContainer">
                    {allergensContained}
                  </div>
                </div>
              )}
              {item.itemModifiableVector?.length !== 0 && (
                <div>
                  <DefaultTitle
                    type={headingNumber + 1}
                    classNameProp={"s12 w7 grayText"}
                  >
                    Allergens Modifiable
                  </DefaultTitle>
                  <div className="menuAllergenTokenContainer">
                    {allergensModifiable}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="menuItemCardVerification">
            <div className="flexColumn gap10">
              <div className="flexRow gap10">
                <div
                  className={`dottedDiv alignCenter ${
                    verified ? "greenDot" : "redDot"
                  }`}
                ></div>
                <DefaultText classNameProp={"s12 w6 alignCenter blackText"}>
                  {verified ? "Verified" : "Unverified"}
                </DefaultText>
              </div>
              <DefaultText classNameProp={"s12 blackText"}>
                This dish has {!verified ? "never" : ""} been verified.
              </DefaultText>
              <EditButton
                classNameProp={"purpleEdit"}
                text="Verify Ingredients"
                onClick={handleClick}
              />
            </div>
            <EditButton text="Edit Info" />
          </div>
        </div>
        <Modal
          show={show}
          setShowToggle={setShow}
          classNameProp={"minimumContainer"}
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
          >
            <div>
              <DefaultText classNameProp={"s20 w7"}>
                Edit and Verify item contents:
              </DefaultText>
            </div>
            <div>
              <div style={{ width: "100%" }}>
                <DefaultText classNameProp={"s14 w6 grayText inline"}>
                  Click Once{" "}
                </DefaultText>
                <DefaultText classNameProp={" s14 grayText inline"}>
                  to mark or remove contains.{" "}
                </DefaultText>
                <DefaultText classNameProp={" s14 w6 inline grayText"}>
                  Contains means this ingredient is present in the dish.
                </DefaultText>
                <br />
                <DefaultText classNameProp={" s14 grayText w6 inline"}>
                  Click Again{" "}
                </DefaultText>
                <DefaultText classNameProp={"grayText s14 inline"}>
                  to mark as modifiable.{" "}
                </DefaultText>
                <DefaultText classNameProp={"s14 w6 inline grayText"}>
                  Modifiable means this ingredient can be removed or replaced.
                </DefaultText>
              </div>
              <div className="menuAllergenTokenContainer">
                <AllergenToken allergen={"contains"} contains={true} />
                <AllergenToken allergen={"modifiable"} modifiable={true} />
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              width: "100%",
              gap: "50px",
              alignItems: "baseline",
            }}
          >
            <div className="textTokenContainer">
              <DefaultText classNameProp={"s18 w7"}>Food Content</DefaultText>
              <div className="menuAllergenTokenContainer">
                {Object.keys(allergens)
                  .slice(0, 3)
                  .map((allergenItem, index) => {
                    const allergen = allergens[allergenItem];
                    return (
                      <AllergenToken
                        key={"allergensContent1" + index}
                        allergen={allergen.name}
                        contains={allergen.contains}
                        modifiable={allergen.modifiable}
                        handleClick={() => toggleAllergen(allergen.id)}
                      />
                    );
                  })}
              </div>
            </div>
            <div className="textTokenContainer">
              <DefaultText classNameProp={"s18 w7"}>Proteins</DefaultText>
              <div className="menuAllergenTokenContainer">
                {Object.keys(allergens)
                  .slice(3, 11)
                  .map((allergenItem, index) => {
                    const allergen = allergens[allergenItem];
                    return (
                      <AllergenToken
                        key={"allergensContent2" + index}
                        allergen={allergen.name}
                        contains={allergen.contains}
                        modifiable={allergen.modifiable}
                        handleClick={() => toggleAllergen(allergen.id)}
                      />
                    );
                  })}
              </div>
            </div>
            <div className="textTokenContainer">
              <DefaultText classNameProp={"s18 w7"}>Grains</DefaultText>
              <div className="menuAllergenTokenContainer">
                {Object.keys(allergens)
                  .slice(11, 15)
                  .map((allergenItem, index) => {
                    const allergen = allergens[allergenItem];
                    return (
                      <AllergenToken
                        key={"allergensContent3" + index}
                        allergen={allergen.name}
                        contains={allergen.contains}
                        modifiable={allergen.modifiable}
                        handleClick={() => toggleAllergen(allergen.id)}
                      />
                    );
                  })}
              </div>
            </div>
            <div className="textTokenContainer">
              <DefaultText classNameProp={"s18 w7"}>
                Dairy & Animal Products
              </DefaultText>
              <div className="menuAllergenTokenContainer">
                {Object.keys(allergens)
                  .slice(15, 19)
                  .map((allergenItem, index) => {
                    const allergen = allergens[allergenItem];
                    return (
                      <AllergenToken
                        key={"allergensContent4" + index}
                        allergen={allergen.name}
                        contains={allergen.contains}
                        modifiable={allergen.modifiable}
                        handleClick={() => toggleAllergen(allergen.id)}
                      />
                    );
                  })}
              </div>
            </div>
            <div className="textTokenContainer">
              <DefaultText classNameProp={"s18 w7"}>Nuts & Seeds</DefaultText>
              <div className="menuAllergenTokenContainer">
                {Object.keys(allergens)
                  .slice(19, 24)
                  .map((allergenItem, index) => {
                    const allergen = allergens[allergenItem];
                    return (
                      <AllergenToken
                        key={"allergensContent5" + index}
                        allergen={allergen.name}
                        contains={allergen.contains}
                        modifiable={allergen.modifiable}
                        handleClick={() => toggleAllergen(allergen.id)}
                      />
                    );
                  })}
              </div>
            </div>
            <div className="textTokenContainer">
              <DefaultText classNameProp={"s18 w7"}>
                Fruits, Vegetables, & Flavours
              </DefaultText>
              <div className="menuAllergenTokenContainer">
                {Object.keys(allergens)
                  .slice(24, 31)
                  .map((allergenItem, index) => {
                    const allergen = allergens[allergenItem];
                    return (
                      <AllergenToken
                        key={"allergensContent6" + index}
                        allergen={allergen.name}
                        contains={allergen.contains}
                        modifiable={allergen.modifiable}
                        handleClick={() => toggleAllergen(allergen.id)}
                      />
                    );
                  })}
              </div>
            </div>
          </div>

          <div className="buttonContainer">
            <DefaultButton
              text={"cancel"}
              classNameProp={"borderButton s14 w6 paddingV12 width140"}
              onClick={() => setShow(false)}
            />
            <DefaultButton
              text={"save"}
              classNameProp={"borderButtonRed s14 w6 paddingV12 width140"}
              onClick={handleSave}
            />
            <DefaultButton
              text={verified ? "Unverify Items" : "Verify Items"}
              classNameProp={"purple s14 w6 paddingV12 width180"}
              onClick={handleVerify}
            />
          </div>
        </Modal>
      </>
    );
  }
);

MenuItemCard.defaultProps = {
  name: "",
  price: "",
  desc: "",
  allergensContained: <></>,
  allergensModifiable: <></>,
};

export default MenuItemCard;
