import React from "react";
import Nestable from "react-nestable";
import "react-nestable/dist/styles/index.css";
import DefaultTitle from "../Components/DefaultTitle";
import "./NestedList.css";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  dishesChangedState,
  SelectedSectionIndex,
  sortableFieldState,
} from "../States/Atoms";
import { MENU_TYPES } from "../config/constants";

const NestedList = ({
  sortedItems,
  onChange,
  setAlertWithMessage,
  scrollToView,
}) => {
  const setSelectedItems = useSetRecoilState(SelectedSectionIndex);
  const sortable = useRecoilValue(sortableFieldState);
  const setChangesMade = useSetRecoilState(dishesChangedState);

  const confirmChange = ({ dragItem, destinationParent }) => {
    if (sortable !== "") {
      setAlertWithMessage("You can't change the order of items while sorting.");
      return false;
    }
    if (dragItem.type === MENU_TYPES.MENU && !destinationParent) return true;
    if (!destinationParent) return false;
    return (destinationParent.accepts || []).indexOf(dragItem.type) > -1;
  };

  const handleClick = (item) => {
    if (item?.type === MENU_TYPES.MENU) {
      //find index of menu
      const index = sortedItems.findIndex((i) => i.id === item.id);
      setSelectedItems([index, 0]);
    } else if (item?.type === MENU_TYPES.SECTION) {
      //find index of parent menu
      const index = sortedItems.findIndex(
        (i) => i.itemName === item.parentMenu
      );
      //find index of section
      const sectionIndex = sortedItems[index].children.findIndex(
        (i) => i.itemName === item.itemName
      );
      setSelectedItems([index, sectionIndex]);
    } else if (item?.type === MENU_TYPES.ITEM) {
      const index = sortedItems.findIndex(
        (menuItem) => menuItem.itemName === item.itemMenu
      );
      const childrenSections = sortedItems[index].children;

      const sectionIndex = childrenSections.findIndex(
        (section) => section.itemName === item.itemSection
      );
      setSelectedItems([index, sectionIndex]);
      scrollToView(item.itemID);
    }
  };

  const handleChange = ({ items, dragItem, targetPath }) => {
    const childDoesntExist = items.find(
      (item) =>
        item.children.length === 0 ||
        item.children.find((child) => child.children.length === 0)
    );
    if (childDoesntExist) {
      //Non valid changes to the tree
      onChange(sortedItems);
      setAlertWithMessage("You can't have empty menus or sections.");
    } else {
      //Rename Menu and section if changes are made and valid
      setChangesMade(true);
      if (dragItem.type === MENU_TYPES.MENU) {
        const [menuIndex] = targetPath;
        setSelectedItems([menuIndex, 0]);
        onChange(items);
      } else if (dragItem.type === MENU_TYPES.SECTION) {
        //rename all items in subsection to have new section name
        const [menuIndex, sectionIndex] = targetPath;
        const newSectionName = dragItem.itemName;
        const newItems = items[menuIndex].children[sectionIndex].children.map(
          (item) => {
            return {
              ...item,
              itemSection: newSectionName,
            };
          }
        );
        items[menuIndex].children[sectionIndex].children = newItems;
        setSelectedItems([menuIndex, sectionIndex]);
        onChange(items);
      } else {
        const [menuIndex, sectionIndex, itemIndex] = targetPath;
        const newMenuName = items[menuIndex].itemName;
        const newSectionName = items[menuIndex].children[sectionIndex].itemName;
        dragItem.itemSection = newSectionName;
        dragItem.itemMenu = newMenuName;
        items[menuIndex].children[sectionIndex].children[itemIndex] = dragItem;
        setSelectedItems([menuIndex, sectionIndex]);
        onChange(items);
      }
    }
  };

  const renderItem = ({ item, collapseIcon, handler }, handleClick) => {
    return (
      <label
        className="nestedItem"
        onClick={() => {
          handleClick(item);
        }}
      >
        {collapseIcon}
        <span
          style={{
            margin: "0px",
          }}
        >
          {item?.type === MENU_TYPES.ITEM && (
            <img src="/images/dishNameDrag.svg" alt="item drag" />
          )}
          {item?.type === MENU_TYPES.MENU && (
            <img src="/images/menuIcon.svg" alt="menu icon" />
          )}
          {item?.type === MENU_TYPES.SECTION && (
            <img src="/images/sectionNameDrag.svg" alt="section drag" />
          )}
        </span>
        {handler}
        <DefaultTitle type={5} classNameProp={"s12 w5 blackText"}>
          {item.itemName}
        </DefaultTitle>
      </label>
    );
  };

  return (
    <>
      {sortedItems.length ? (
        <Nestable
          items={sortedItems}
          renderItem={({ ...props }) => renderItem({ ...props }, handleClick)}
          maxDepth={3}
          collapsed={false}
          onChange={(obj) => {
            handleChange({ ...obj });
          }}
          renderCollapseIcon={({ isCollapsed }) => (
            <span className="">
              {isCollapsed ? (
                <img src="/images/sideArrow.svg" alt="Collapsed arrow" />
              ) : (
                <img src="/images/downArrow.svg" alt="Expanded arrow" />
              )}
            </span>
          )}
          confirmChange={confirmChange}
        />
      ) : (
        <div className="center">No items to display</div>
      )}
    </>
  );
};

export default NestedList;
