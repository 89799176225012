import React from "react";
import "./AlertComponent.css";
const AlertSvg = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22 26.0578C22.4214 26.0578 22.8255 25.8904 23.1235 25.5924C23.4215 25.2944 23.5889 24.8903 23.5889 24.4689V17.1111C23.5889 16.6897 23.4215 16.2856 23.1235 15.9876C22.8255 15.6896 22.4214 15.5222 22 15.5222C21.5786 15.5222 21.1744 15.6896 20.8765 15.9876C20.5785 16.2856 20.4111 16.6897 20.4111 17.1111V24.4444C20.4078 24.6551 20.4465 24.8644 20.5249 25.06C20.6033 25.2556 20.7199 25.4337 20.8677 25.5838C21.0156 25.7339 21.1918 25.8532 21.3862 25.9345C21.5806 26.0159 21.7892 26.0578 22 26.0578Z"
        fill="white"
      />
      <path
        d="M21.9389 31.4967C22.9514 31.4967 23.7723 30.6759 23.7723 29.6634C23.7723 28.6508 22.9514 27.83 21.9389 27.83C20.9264 27.83 20.1056 28.6508 20.1056 29.6634C20.1056 30.6759 20.9264 31.4967 21.9389 31.4967Z"
        fill="white"
      />
      <path
        d="M37.07 31.2156L25.1656 9.28894C24.8504 8.70987 24.3849 8.22648 23.8182 7.88968C23.2514 7.55288 22.6043 7.37512 21.945 7.37512C21.2857 7.37512 20.6386 7.55288 20.0719 7.88968C19.5051 8.22648 19.0396 8.70987 18.7245 9.28894L6.80779 31.2156C6.49589 31.7755 6.33617 32.4073 6.34455 33.0481C6.35294 33.6889 6.52914 34.3164 6.85558 34.8679C7.18203 35.4194 7.64733 35.8757 8.20509 36.1913C8.76285 36.5069 9.3936 36.6708 10.0345 36.6667H33.8433C34.479 36.6673 35.1038 36.5026 35.6566 36.1889C36.2094 35.8752 36.6711 35.4231 36.9965 34.8771C37.3219 34.3311 37.4997 33.7099 37.5126 33.0744C37.5255 32.4389 37.373 31.811 37.07 31.2523V31.2156ZM34.8945 33.5867C34.7863 33.7692 34.6325 33.9203 34.4482 34.0253C34.2639 34.1303 34.0555 34.1856 33.8433 34.1856H10.0345C9.82201 34.1862 9.61307 34.1314 9.42826 34.0266C9.24346 33.9218 9.08915 33.7707 8.98057 33.588C8.87199 33.4054 8.81289 33.1977 8.80909 32.9853C8.80529 32.7728 8.85693 32.5631 8.9589 32.3767L20.8633 10.4501C20.9681 10.256 21.1233 10.0939 21.3127 9.98097C21.502 9.868 21.7184 9.80836 21.9389 9.80836C22.1594 9.80836 22.3758 9.868 22.5651 9.98097C22.7545 10.0939 22.9097 10.256 23.0145 10.4501L34.9189 32.3767C35.0202 32.5632 35.0712 32.7728 35.067 32.985C35.0627 33.1971 35.0032 33.4045 34.8945 33.5867Z"
        fill="white"
      />
    </svg>
  );
};

const SuccessSvg = () => {
  return (
    <svg
      width="26"
      height="25"
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1875 1.31165H6.6875C3.92608 1.31165 1.6875 3.55022 1.6875 6.31165V18.8116C1.6875 21.5731 3.92608 23.8116 6.6875 23.8116H19.1875C21.9489 23.8116 24.1875 21.5731 24.1875 18.8116V6.31165C24.1875 3.55022 21.9489 1.31165 19.1875 1.31165Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.1875 12.5616L12 15.0616L16.6875 10.0616"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

/**
 * Alert Component
 * @prop {string} text - The text to display in the alert.
 * @prop {string} type - The type of alert to display. "error" or "success".
 * @prop {function} onClick - The function to call when the alert is clicked.
 * @prop {string} classNameProp - The class name to apply to the alert.
 * @prop {boolean} fade - Whether or not to fade the alert.
 * @prop {function} setFade - The function to call when the alert is faded.
 * @prop {number} fadeDuration - The duration of the out animation.
 * @returns
 */
const AlertComponent = ({
  classNameProp,
  text,
  type = "success",
  fade = false,
  setFade = () => {},
  fadeDuration = 1000,
}) => {
  const AlertType = () => {
    return type === "error" ? <AlertSvg /> : <SuccessSvg />;
  };
  return (
    <div
      className={`alertComponent ${classNameProp} ${type}`}
      style={{
        animation: fade ? `fadeOut ${fadeDuration}ms` : "",
      }}
      onAnimationEnd={() => setFade(false)}
    >
      <AlertType />
      <h2 className="alertText">{text}</h2>
    </div>
  );
};

export default AlertComponent;
